import About from ".";

const AboutComponent = ({ data }) => {
    console.log(data)
    
    return (
        <About.Root>
            <About.Title icon={data.icon} text={data.title} />
            <About.Topics data={data} />
        </About.Root>
    );
}

export default AboutComponent;
