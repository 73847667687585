import { mdiAccessPoint, mdiGold, mdiDevTo, mdiLanguageCss3, mdiLanguageHtml5, mdiLanguageJavascript, mdiLanguageTypescript, mdiNodejs, mdiReact, mdiRobot } from "@mdi/js";
export const AboutsConfig = [
    {
        label: 'Notícias',
        title: 'Notícias de Tecnologia',
        path: '/sobre/noticias',
        icon: mdiAccessPoint,
        topics: [
            {
                label: 'Inovações da Meta 🚀 Lançamento: Óculos de Realidade Aumentada Orion',
                description: 'As últimas inovações tecnológicas da Meta, com foco no novo lançamento dos óculos de realidade aumentada, Orion.',
                subtopics: [
                    {
                        primary: 'Meta lança Orion: os primeiros óculos de realidade aumentada verdadeiros',
                        secondary: `A Meta revelou o Orion, o primeiro par de óculos de realidade aumentada (RA) verdadeiros da empresa. Conhecido anteriormente como Projeto Nazare, o Orion é um avanço significativo no campo da computação imersiva, combinando a aparência e sensação de um par de óculos comum com os recursos de realidade aumentada. O Orion é o resultado de inovações em várias áreas da computação moderna e promete transformar a maneira como interagimos com o mundo físico e digital.
    
    Além disso, a Meta está fornecendo acesso ao Orion a funcionários da empresa e a públicos selecionados para aprendizado e desenvolvimento contínuo da linha de produtos de RA. Os óculos permitem que os usuários acessem experiências digitais imersivas e interajam com hologramas, assistentes inteligentes e muito mais, tudo sem comprometer a conexão com o mundo físico ao seu redor.`,
                        image: 'https://i.ibb.co/kyhCGbN/image.png',
                        alt: 'Óculos de realidade aumentada Orion da Meta',
                        caption: 'Óculos Orion integrando experiências de RA — Foto: Meta',
                        link: 'https://about.fb.com/news/2024/09/introducing-orion-our-first-true-augmented-reality-glasses/'
                    },
                    {
                        primary: 'Por que os óculos de realidade aumentada são o futuro da computação?',
                        secondary: `A Meta acredita que os óculos de realidade aumentada são essenciais para o próximo grande avanço na computação voltada para o ser humano. Eles permitem experiências digitais que superam os limites das telas de smartphones, integrando IA contextual e proporcionando displays holográficos imersivos em qualquer lugar. Os óculos Orion da Meta são leves, projetados para serem usados tanto em ambientes internos quanto externos, oferecendo uma experiência digital fluida e interativa.`,
                        image: 'https://i.ibb.co/qjfqYn3/image.png',
                        alt: 'Óculos de realidade aumentada Orion em uso',
                        caption: 'Óculos Orion integrando experiências de RA — Foto: Meta',
                        link: 'https://about.fb.com/news/2024/09/introducing-orion-our-first-true-augmented-reality-glasses/'
                    },
                    {
                        primary: 'Evolução dos óculos inteligentes com o Orion',
                        secondary: `A Orion é um marco na evolução dos óculos inteligentes, seguindo o sucesso da Ray-Ban Meta, que introduziu a ideia de óculos sem tela com assistentes de IA integrados. Com os óculos de RA Orion, a Meta vai além, proporcionando displays holográficos e assistência personalizada por IA em um formato confortável para uso diário. O Orion representa o futuro da tecnologia de realidade aumentada, combinando conveniência, imersão e alta conectividade.`,
                        image: 'https://i.ibb.co/17dKqz1/image.png',
                        alt: 'Óculos de realidade aumentada Orion com hologramas',
                        caption: 'Óculos Orion integrando experiências de RA — Foto: Meta',
                        link: 'https://about.fb.com/news/2024/09/introducing-orion-our-first-true-augmented-reality-glasses/'
                    },
                ],
            },
        ],
    },
    
    {
        label: 'Dicas',
        title: 'Dicas Gerais de Desenvolvimento',
        path: '/sobre/dicas',
        icon: mdiGold,
        topics: [
            {
                label: 'Dicas para Frontend',
                description: 'Veja dicas sobre boas práticas para desenvolvimento frontend, desde acessibilidade até otimização de performance.',
                subtopics: [
                    {
                        primary: '1. Use sempre semantic HTML',
                        secondary: 'Semântica melhora a acessibilidade e a SEO da sua aplicação. Use tags como <header>, <footer>, <article>, <section> para descrever corretamente a estrutura da página.',
                        codeSnippet: `
      <article>
        <header>
          <h1>Título do Artigo</h1>
        </header>
        <section>
          <p>Este é um parágrafo dentro de uma seção.</p>
        </section>
        <footer>© 2024 Seu Nome</footer>
      </article>
                `,
                    },
                    {
                        primary: '2. Otimize o desempenho de renderização',
                        secondary: 'Em React, memorize funções e valores com useMemo e useCallback para evitar renders desnecessários. Isso ajuda a melhorar o desempenho da aplicação.',
                        codeSnippet: `
      import { useMemo, useCallback } from 'react';
      
      const Component = ({ items }) => {
        const memorizedItems = useMemo(() => items.map(item => item * 2), [items]);
        const handleClick = useCallback(() => {
          console.log('Clicked');
        }, []);
        
        return <button onClick={handleClick}>Clique aqui</button>;
      };
                `,
                    },
                    {
                        primary: '3. Evite CSS pesado',
                        secondary: 'Use CSS modularizado, ou bibliotecas como Styled Components ou Emotion, para manter o código CSS limpo e evitar a poluição global de estilos.',
                        codeSnippet: `
      import styled from 'styled-components';
      
      const Button = styled.button\`
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
      \`;
      
      export default Button;
                `,
                    },
                ],
            },
            {
                label: 'Dicas para Backend',
                description: 'Boas práticas e dicas para melhorar o desempenho e escalabilidade no desenvolvimento de backend.',
                subtopics: [
                    {
                        primary: '1. Evite consultas N+1',
                        secondary: 'Em bancos de dados relacionais, evite a consulta N+1, utilizando joins e otimizando queries com técnicas como eager loading.',
                        codeSnippet: `
      SELECT users.*, posts.*
      FROM users
      JOIN posts ON users.id = posts.user_id
                `,
                    },
                    {
                        primary: '2. Use loggers com parcimônia',
                        secondary: 'Adicione logs suficientes para facilitar a depuração, mas evite sobrecarregar a aplicação com logs excessivos, principalmente em ambientes de produção.',
                        codeSnippet: `
      const express = require('express');
      const app = express();
      const logger = require('morgan');
      
      // Log apenas em modo de desenvolvimento
      if (process.env.NODE_ENV === 'development') {
        app.use(logger('dev'));
      }
      
      app.listen(3000, () => {
        console.log('Servidor rodando na porta 3000');
      });
                `,
                    },
                    {
                        primary: '3. Escale suas APIs com filas',
                        secondary: 'Para processar tarefas pesadas, como envio de e-mails, utilize filas como RabbitMQ ou Redis para que sua API possa continuar respondendo rapidamente.',
                        codeSnippet: `
      const queue = require('bull');
      
      // Cria uma nova fila para envio de e-mails
      const emailQueue = new queue('email');
      
      // Adiciona uma tarefa de envio de e-mail à fila
      emailQueue.add({ to: 'user@example.com', subject: 'Bem-vindo!' });
      
      // Processa a fila de envio de e-mails
      emailQueue.process(async (job) => {
        sendEmail(job.data);
      });
                `,
                    },
                ],
            },
            {
                label: 'Dicas para Mobile',
                description: 'Práticas essenciais para melhorar o desempenho e a experiência de usuários em aplicações móveis.',
                subtopics: [
                    {
                        primary: '1. Otimize o uso de memória',
                        secondary: 'Em aplicativos móveis, preste atenção ao uso de memória. Evite manter muitos dados em cache e libere recursos assim que eles não forem mais necessários.',
                        codeSnippet: `
      useEffect(() => {
        const unsubscribe = memoryIntensiveTask();
      
        return () => {
          // Libera a memória quando o componente é desmontado
          unsubscribe();
        };
      }, []);
                `,
                    },
                    {
                        primary: '2. Priorize a experiência offline',
                        secondary: 'Usuários de dispositivos móveis podem estar frequentemente offline. Adicione funcionalidade offline e sincronização em segundo plano para melhorar a experiência do usuário.',
                        codeSnippet: `
      import NetInfo from "@react-native-community/netinfo";
      
      NetInfo.fetch().then(state => {
        if (!state.isConnected) {
          // Usuário está offline, salvar os dados localmente
          saveDataLocally(data);
        } else {
          // Sincronizar dados quando o usuário voltar online
          syncDataWithServer();
        }
      });
                `,
                    },
                    {
                        primary: '3. Evite re-renderizações desnecessárias',
                        secondary: 'Em React Native, use componentes memoizados e otimize os hooks para evitar renderizações desnecessárias que impactem a performance do app.',
                        codeSnippet: `
      import React, { memo } from 'react';
      
      const MyComponent = memo(({ data }) => {
        return <Text>{data}</Text>;
      });
                `,
                    },
                ],
            },
        ],
    },

    {
        label: 'Inteligência Artificial',
        title: 'Sobre IA',
        path: '/sobre/ia',
        icon: mdiRobot,
        topics: [
            {
                label: 'O que é Inteligência Artificial?',
                description: 'A Inteligência Artificial (IA) é uma área da ciência da computação que foca no desenvolvimento de sistemas que podem executar tarefas que normalmente exigiriam inteligência humana. Essas tarefas incluem reconhecimento de fala, aprendizado, percepção visual, tomada de decisão e tradução entre idiomas. A IA pode ser dividida em duas categorias principais: IA forte e IA fraca.'
            },
            {
                label: 'Tipos de IA',
                description: 'Existem diferentes tipos de IA, que variam em nível de complexidade e autonomia. Eles podem ser classificados em IA fraca, IA geral e IA superinteligente.',
                subtopics: [
                    {
                        primary: '1. IA Fraca (Estreita)',
                        secondary: 'A IA fraca, também conhecida como IA estreita, é especializada em uma única tarefa e não possui consciência ou capacidade de realizar tarefas além do seu propósito específico. Exemplos incluem assistentes virtuais como Siri e Alexa, e algoritmos de recomendação em plataformas como Netflix e YouTube.',
                        codeSnippet: `
        // Exemplo de um sistema de IA estreita
        function recomendacaoFilmes(usuario) {
            const filmes = ['Ação', 'Comédia', 'Drama'];
            return filmes[Math.floor(Math.random() * filmes.length)];
        }
        
        console.log(recomendacaoFilmes('Usuário'));
                        `,
                    },
                    {
                        primary: '2. IA Geral',
                        secondary: 'A IA geral, ou IA forte, é uma forma hipotética de IA que tem a capacidade de entender, aprender e aplicar inteligência em uma ampla gama de tarefas, assim como os humanos. Ela ainda não foi alcançada, mas é o objetivo final de muitos pesquisadores de IA.',
                        codeSnippet: `
        // Exemplo conceitual de IA Geral (ainda não alcançada)
        function inteligenciaGeral(entrada) {
            // Lógica adaptativa e aprendizado não supervisionado
            return 'Resolvendo uma ampla gama de tarefas com inteligência humana';
        }
    
        console.log(inteligenciaGeral('Tarefa Desconhecida'));
                        `,
                    },
                    {
                        primary: '3. IA Superinteligente',
                        secondary: 'A IA superinteligente vai além da capacidade humana em todas as áreas, incluindo tomada de decisão, resolução de problemas e criatividade. Embora ainda não exista, muitos especialistas alertam sobre os riscos potenciais dessa tecnologia.',
                        codeSnippet: `
        // Exemplo conceitual de IA superinteligente
        function iaSuperinteligente(entrada) {
            return 'Soluções além da capacidade humana';
        }
    
        console.log(iaSuperinteligente('Tarefa Complexa'));
                        `,
                    },
                ],
            },
            {
                label: 'Aprendizado de Máquina (Machine Learning)',
                description: 'O Aprendizado de Máquina (Machine Learning - ML) é um subcampo da IA que envolve o desenvolvimento de algoritmos que permitem que máquinas aprendam e melhorem automaticamente a partir de dados sem serem explicitamente programadas para isso. Existem três tipos principais de aprendizado de máquina: supervisionado, não supervisionado e por reforço.',
                subtopics: [
                    {
                        primary: '1. Aprendizado Supervisionado',
                        secondary: 'No aprendizado supervisionado, o modelo é treinado com base em um conjunto de dados rotulados, o que significa que cada exemplo de treinamento tem uma resposta correta associada. O objetivo é que o modelo aprenda a prever a saída correta para novos dados de entrada.',
                        codeSnippet: `
        // Exemplo básico de aprendizado supervisionado (pseudo código)
        const dataset = [
            { entrada: [2, 3], saida: 'Classe A' },
            { entrada: [1, 5], saida: 'Classe B' },
        ];
    
        const modelo = treinarModelo(dataset);
        const predicao = modelo.prever([3, 4]);
        
        console.log('Predição:', predicao);  // Resultado esperado: 'Classe A'
                        `,
                    },
                    {
                        primary: '2. Aprendizado Não Supervisionado',
                        secondary: 'No aprendizado não supervisionado, os dados de entrada não têm rótulos. O modelo tenta encontrar padrões e agrupamentos nos dados. Exemplos incluem algoritmos de clustering como k-means.',
                        codeSnippet: `
        // Exemplo básico de aprendizado não supervisionado (pseudo código)
        const dados = [
            [1.2, 2.3],
            [4.5, 5.6],
            [7.8, 9.0],
        ];
    
        const clusters = agruparDados(dados, numeroDeClusters = 2);
        console.log('Clusters:', clusters);
                        `,
                    },
                    {
                        primary: '3. Aprendizado por Reforço',
                        secondary: 'No aprendizado por reforço, um agente interage com um ambiente e aprende a tomar decisões para maximizar uma recompensa ao longo do tempo. Este tipo de aprendizado é comum em jogos e robótica.',
                        codeSnippet: `
        // Exemplo básico de aprendizado por reforço (pseudo código)
        function agenteReforco(estado) {
            if (estado === 'bom') {
                return 'Tomar ação positiva';
            } else {
                return 'Tomar ação corretiva';
            }
        }
    
        const acao = agenteReforco('bom');
        console.log('Ação tomada:', acao);  // Resultado esperado: 'Tomar ação positiva'
                        `,
                    },
                ],
            },
            {
                label: 'Redes Neurais',
                description: 'Redes neurais são modelos inspirados no cérebro humano que tentam simular a forma como os neurônios processam informações. Elas são compostas por camadas de nós (neurônios), onde cada nó representa uma função matemática. Existem diferentes tipos de redes neurais, como redes neurais feedforward, redes neurais convolucionais e redes neurais recorrentes.',
                subtopics: [
                    {
                        primary: '1. Redes Neurais Simples',
                        secondary: 'As redes neurais feedforward são o tipo mais simples de rede neural, onde a informação flui em uma direção, da entrada para a saída, passando por uma ou mais camadas ocultas.',
                        codeSnippet: `
        // Exemplo básico de uma rede neural feedforward (pseudo código)
        function feedforward(entrada) {
            const camadaOculta = ativacao(entrada * peso1 + bias1);
            const saida = ativacao(camadaOculta * peso2 + bias2);
            return saida;
        }
    
        const resultado = feedforward([1, 2, 3]);
        console.log('Saída da rede neural:', resultado);
                        `,
                    },
                    {
                        primary: '2. Redes Neurais Convolucionais (CNN)',
                        secondary: 'Redes neurais convolucionais são amplamente utilizadas em reconhecimento de imagens e vídeos. Elas utilizam camadas convolucionais para filtrar e aprender características espaciais dos dados de entrada.',
                        codeSnippet: `
        // Exemplo básico de uma rede convolucional (pseudo código)
        function convolucao(entrada, filtro) {
            return entrada.map((pixel, i) => pixel * filtro[i]);
        }
    
        const imagem = [1, 0, 1, 0];  // Exemplo de imagem simplificada
        const filtro = [1, -1, 1, -1];  // Exemplo de filtro convolucional
        const resultado = convolucao(imagem, filtro);
        console.log('Resultado da convolução:', resultado);
                        `,
                    },
                    {
                        primary: '3. Redes Neurais Recorrentes (RNN)',
                        secondary: 'As redes neurais recorrentes são usadas para processar sequências de dados, como séries temporais ou textos. Elas mantêm um estado interno, o que lhes permite lembrar informações anteriores na sequência.',
                        codeSnippet: `
        // Exemplo básico de uma rede recorrente (pseudo código)
        function redeRecorrente(entradaSequencial) {
            let estado = 0;
            entradaSequencial.forEach(entrada => {
                estado = ativacao(entrada + estado);
            });
            return estado;
        }
    
        const sequencia = [1, 2, 3, 4];
        const resultado = redeRecorrente(sequencia);
        console.log('Estado final:', resultado);
                        `,
                    },
                ],
            },
            {
                label: 'Processamento de Linguagem Natural (NLP)',
                description: 'O Processamento de Linguagem Natural (Natural Language Processing - NLP) é um subcampo da IA focado em interações entre computadores e linguagem humana. O objetivo do NLP é permitir que máquinas compreendam, interpretem e respondam a entradas de linguagem humana.',
                subtopics: [
                    {
                        primary: '1. Tokenização e Análise de Texto',
                        secondary: 'A tokenização é o processo de dividir um texto em unidades menores, como palavras ou frases, chamadas tokens. Esse é um passo essencial para análise de texto em NLP.',
                        codeSnippet: `
        // Exemplo básico de tokenização (pseudo código)
        function tokenizar(texto) {
            return texto.split(' ');
        }
    
        const texto = 'Este é um exemplo de tokenização';
        const tokens = tokenizar(texto);
        console.log('Tokens:', tokens);  // Resultado esperado: ['Este', 'é', 'um', 'exemplo', 'de', 'tokenização']
                        `,
                    },
                    {
                        primary: '2. Análise de Sentimento',
                        secondary: 'A análise de sentimento é a tarefa de determinar o sentimento ou emoção por trás de um texto. Ela pode ser usada para identificar se um texto expressa emoções positivas, negativas ou neutras.',
                        codeSnippet: `
        // Exemplo básico de análise de sentimento (pseudo código)
        function analisarSentimento(texto) {
            if (texto.includes('bom')) {
                return 'Positivo';
            } else if (texto.includes('ruim')) {
                return 'Negativo';
            } else {
                return 'Neutro';
            }
        }
    
        const sentimento = analisarSentimento('Este produto é muito bom!');
        console.log('Sentimento:', sentimento);  // Resultado esperado: 'Positivo'
                        `,
                    },
                    {
                        primary: '3. Modelos de Linguagem GPT',
                        secondary: 'Modelos como GPT (Generative Pretrained Transformer) são modelos de linguagem avançados que utilizam técnicas de aprendizado profundo para gerar texto e realizar tarefas como tradução e resumo automático.',
                        codeSnippet: `
        // Exemplo básico de uso de um modelo GPT (pseudo código)
        function gerarTextoGPT(entrada) {
            return 'Texto gerado baseado na entrada: ' + entrada;
        }
    
        const textoGerado = gerarTextoGPT('Como está o clima hoje?');
        console.log('Texto Gerado:', textoGerado);
                        `,
                    },
                ],
            },
            {
                label: 'Visão Computacional',
                description: 'A Visão Computacional é o campo da IA que se concentra em como as máquinas podem interpretar e entender o mundo visual. Ela envolve tarefas como reconhecimento de objetos, detecção de rostos e análise de imagens e vídeos.',
                subtopics: [
                    {
                        primary: '1. Detecção de Objetos',
                        secondary: 'A detecção de objetos identifica a presença de objetos em imagens ou vídeos e desenha caixas delimitadoras ao redor deles. Essa técnica é amplamente utilizada em segurança, direção autônoma e outras áreas.',
                        codeSnippet: `
        // Exemplo básico de detecção de objetos (pseudo código)
        function detectarObjetos(imagem) {
            const objetos = ['carro', 'pessoa', 'bicicleta'];  // Objetos detectados
            return objetos;
        }
    
        const imagem = 'imagem-de-teste.jpg';
        const objetosDetectados = detectarObjetos(imagem);
        console.log('Objetos Detectados:', objetosDetectados);
                        `,
                    },
                    {
                        primary: '2. Reconhecimento Facial',
                        secondary: 'O reconhecimento facial identifica e verifica rostos humanos em uma imagem ou vídeo, mapeando as características faciais.',
                        codeSnippet: `
        // Exemplo básico de reconhecimento facial (pseudo código)
        function reconhecerRosto(imagem) {
            const rosto = { nome: 'Pedro', probabilidade: 98 };  // Detecção simulada
            return rosto;
        }
    
        const rostoReconhecido = reconhecerRosto('imagem-com-rosto.jpg');
        console.log('Rosto Reconhecido:', rostoReconhecido);
                        `,
                    },
                    {
                        primary: '3. Segmentação de Imagens',
                        secondary: 'A segmentação de imagens divide uma imagem em regiões, identificando os diferentes objetos e suas respectivas áreas.',
                        codeSnippet: `
        // Exemplo básico de segmentação de imagem (pseudo código)
        function segmentarImagem(imagem) {
            return 'Segmentação completa: objetos divididos por áreas da imagem';
        }
    
        const resultado = segmentarImagem('imagem-de-exemplo.jpg');
        console.log('Resultado da Segmentação:', resultado);
                        `,
                    },
                ],
            },
            {
                label: 'Aprendizado Profundo (Deep Learning)',
                description: 'Aprendizado profundo é um tipo de aprendizado de máquina que utiliza redes neurais profundas para modelar padrões complexos em grandes quantidades de dados. Ele é amplamente utilizado em áreas como visão computacional, processamento de linguagem natural e reconhecimento de fala.',
                subtopics: [
                    {
                        primary: '1. Redes Neurais Profundas',
                        secondary: 'As redes neurais profundas possuem várias camadas ocultas, o que permite que elas aprendam características complexas nos dados.',
                        codeSnippet: `
        // Exemplo básico de uma rede neural profunda (pseudo código)
        function redeNeuralProfunda(entrada) {
            const camadas = [camada1(entrada), camada2(), camada3()];
            return camadas[camadas.length - 1];  // Resultado final após as camadas
        }
    
        const resultado = redeNeuralProfunda([1, 2, 3]);
        console.log('Resultado:', resultado);
                        `,
                    },
                    {
                        primary: '2. Redes Neurais Convolucionais (CNN) em Deep Learning',
                        secondary: 'As redes convolucionais são comumente usadas em aprendizado profundo para analisar dados visuais. Elas filtram as imagens para aprender características como bordas e texturas.',
                        codeSnippet: `
        // Exemplo básico de uma CNN em Deep Learning (pseudo código)
        function redeConvolucional(entrada) {
            const filtros = [filtro1(entrada), filtro2(), filtro3()];
            return filtros[0];  // Resultado da convolução inicial
        }
    
        const resultadoCNN = redeConvolucional('imagem.jpg');
        console.log('Resultado CNN:', resultadoCNN);
                        `,
                    },
                    {
                        primary: '3. Aprendizado Não Supervisionado com Deep Learning',
                        secondary: 'O aprendizado não supervisionado com redes neurais profundas pode descobrir padrões ocultos em dados sem rótulos, sendo útil para clustering e geração de dados sintéticos.',
                        codeSnippet: `
        // Exemplo básico de aprendizado não supervisionado com Deep Learning (pseudo código)
        function deepLearningNaoSupervisionado(dados) {
            return 'Padrões descobertos sem supervisão';
        }
    
        const padroes = deepLearningNaoSupervisionado([1, 2, 3, 4]);
        console.log('Padrões Descobertos:', padroes);
                        `,
                    },
                ],
            },
        ],
    },
    {
        label: 'React',
        title: 'Sobre React',
        path: '/sobre/react',
        icon: mdiReact,
        topics: [
            {
                label: 'O que é React?',
                description: 'React é uma biblioteca JavaScript para construir interfaces de usuário. É mantida pelo Facebook e uma comunidade de desenvolvedores individuais e empresas.'
            },
            {
                label: 'Componentes em React',
                description: 'Componentes são a base do React. Eles permitem dividir a interface em partes independentes e reutilizáveis, que podem ser tratadas isoladamente.',
                subtopics: [
                    {
                        primary: '1. Componentes Funcionais',
                        secondary: 'Componentes funcionais são funções JavaScript que retornam JSX (uma extensão de sintaxe semelhante ao HTML). Eles são uma maneira simples de definir a UI com base no estado e nas props.',
                        codeSnippet: `
        import React from 'react';
    
        function Saudacao(props) {
            return <h1>Olá, {props.nome}!</h1>;
        }
    
        export default Saudacao;
    
        // Uso:
        // <Saudacao nome="Pedro" />  // Output: Olá, Pedro!
                        `,
                    },
                    {
                        primary: '2. Componentes de Classe',
                        secondary: 'Componentes de classe são mais antigos e utilizados para componentes mais complexos, pois suportam ciclo de vida, estado e outros recursos nativamente.',
                        codeSnippet: `
        import React, { Component } from 'react';
    
        class Saudacao extends Component {
            render() {
                return <h1>Olá, {this.props.nome}!</h1>;
            }
        }
    
        export default Saudacao;
    
        // Uso:
        // <Saudacao nome="Maria" />  // Output: Olá, Maria!
                        `,
                    },
                    {
                        primary: '3. Props em Componentes',
                        secondary: 'Props (propriedades) são os dados passados para os componentes de forma dinâmica. Um componente pode receber múltiplas props e utilizá-las para personalizar seu comportamento.',
                        codeSnippet: `
        import React from 'react';
    
        function Cartao(props) {
            return (
                <div>
                    <h2>{props.titulo}</h2>
                    <p>{props.conteudo}</p>
                </div>
            );
        }
    
        export default Cartao;
    
        // Uso:
        // <Cartao titulo="Bem-vindo!" conteudo="Este é um cartão personalizado." />
                        `,
                    },
                ],
            },
            {
                label: 'JSX',
                description: 'JSX é uma extensão de sintaxe para JavaScript, que permite escrever HTML dentro do JavaScript. É uma maneira intuitiva de criar componentes no React.'
            },
            {
                label: 'Hooks em React',
                description: 'Hooks são funções que permitem usar o estado e outros recursos do React sem escrever uma classe. Eles tornam o código mais simples e reutilizável.',
                subtopics: [
                    {
                        primary: '1. O que são Hooks?',
                        secondary: 'Hooks são funções especiais do React que permitem "enganchar" recursos como estado e ciclo de vida em componentes funcionais. Antes dos hooks, apenas componentes de classe podiam gerenciar estado e lidar com efeitos colaterais.',
                        codeSnippet: `
        import React, { useState } from 'react';
    
        function Contador() {
            const [contador, setContador] = useState(0);  // Hook useState
    
            return (
                <div>
                    <p>Você clicou {contador} vezes</p>
                    <button onClick={() => setContador(contador + 1)}>Clique aqui</button>
                </div>
            );
        }
    
        export default Contador;
    
        // O hook useState permite adicionar e atualizar o estado em um componente funcional.
                        `,
                    },
                    {
                        primary: '2. Hook useState',
                        secondary: 'O useState retorna um array com dois valores: o estado atual e uma função para atualizá-lo.',
                        codeSnippet: `
        import React, { useState } from 'react';
    
        function Contador() {
            const [contador, setContador] = useState(0);
    
            return (
                <div>
                    <p>Contagem: {contador}</p>
                    <button onClick={() => setContador(contador + 1)}>Incrementar</button>
                </div>
            );
        }
    
        export default Contador;
                        `,
                    },
                    {
                        primary: '3. Estado inicial no useState',
                        secondary: 'O valor inicial do estado pode ser qualquer tipo de dado: número, string, array, objeto, etc. Ele define o valor que o estado terá na primeira renderização do componente.',
                        codeSnippet: `
        import React, { useState } from 'react';
    
        function Saudacao() {
            const [nome, setNome] = useState('Visitante');  // Estado inicial é 'Visitante'
    
            return (
                <div>
                    <p>Olá, {nome}!</p>
                    <input
                        type="text"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </div>
            );
        }
    
        export default Saudacao;
    
        // A entrada de texto permite alterar o valor do estado "nome" dinamicamente.
                        `,
                    },
                    {
                        primary: '4. Usando múltiplos estados no mesmo componente',
                        secondary: 'Você pode usar múltiplos hooks useState para gerenciar diferentes partes do estado dentro de um componente.',
                        codeSnippet: `
        import React, { useState } from 'react';
    
        function Perfil() {
            const [nome, setNome] = useState('Visitante');
            const [idade, setIdade] = useState(25);
    
            return (
                <div>
                    <p>Nome: {nome}</p>
                    <p>Idade: {idade}</p>
                    <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    <input type="number" value={idade} onChange={(e) => setIdade(e.target.value)} />
                </div>
            );
        }
    
        export default Perfil;
                        `,
                    },
                    {
                        primary: '5. Hook useEffect',
                        secondary: 'O useEffect é um hook que permite realizar efeitos colaterais, como chamadas a APIs, manipulação de eventos ou atualizações baseadas no estado.',
                        codeSnippet: `
        import React, { useState, useEffect } from 'react';
    
        function Relogio() {
            const [hora, setHora] = useState(new Date());
    
            useEffect(() => {
                const timerID = setInterval(() => setHora(new Date()), 1000);
    
                return () => clearInterval(timerID);  // Limpa o intervalo ao desmontar
            }, []);
    
            return <h2>São {hora.toLocaleTimeString()}</h2>;
        }
    
        export default Relogio;
    
        // O useEffect é usado para iniciar e limpar um intervalo que atualiza a hora.
                        `,
                    },
                    {
                        primary: '6. Limpeza no useEffect',
                        secondary: 'O useEffect pode retornar uma função de limpeza que será executada quando o componente for desmontado ou antes de o efeito ser reexecutado.',
                        codeSnippet: `
        import React, { useState, useEffect } from 'react';
    
        function Temporizador() {
            const [segundos, setSegundos] = useState(0);
    
            useEffect(() => {
                const intervalo = setInterval(() => {
                    setSegundos((prevSegundos) => prevSegundos + 1);
                }, 1000);
    
                return () => clearInterval(intervalo);  // Limpeza quando o componente for desmontado
            }, []);
    
            return <p>Segundos: {segundos}</p>;
        }
    
        export default Temporizador;
                        `,
                    },
                    {
                        primary: '7. Hook useContext',
                        secondary: 'O useContext permite acessar o contexto em componentes funcionais, simplificando o compartilhamento de dados entre componentes sem precisar passar props manualmente.',
                        codeSnippet: `
        import React, { useContext } from 'react';
    
        const TemaContext = React.createContext('claro');
    
        function Botao() {
            const tema = useContext(TemaContext);
    
            return <button style={{ background: tema === 'claro' ? '#fff' : '#333' }}>Clique</button>;
        }
    
        function App() {
            return (
                <TemaContext.Provider value="escuro">
                    <Botao />
                </TemaContext.Provider>
            );
        }
    
        export default App;
                        `,
                    },
                ],
            },
            {
                label: 'Ciclo de Vida dos Componentes',
                description: 'Componentes em React possuem um ciclo de vida que define o comportamento antes, durante e depois de sua renderização.',
                subtopics: [
                    {
                        primary: '1. Ciclo de vida em Componentes de Classe',
                        secondary: 'Em componentes de classe, métodos como componentDidMount, componentDidUpdate e componentWillUnmount permitem executar lógica em momentos específicos do ciclo de vida.',
                        codeSnippet: `
        import React, { Component } from 'react';
    
        class Relogio extends Component {
            constructor(props) {
                super(props);
                this.state = { data: new Date() };
            }
    
            componentDidMount() {
                this.timerID = setInterval(() => this.tick(), 1000);
            }
    
            componentWillUnmount() {
                clearInterval(this.timerID);
            }
    
            tick() {
                this.setState({ data: new Date() });
            }
    
            render() {
                return <h2>São {this.state.data.toLocaleTimeString()}.</h2>;
            }
        }
    
        export default Relogio;
    
        // O componente Relogio atualiza a hora a cada segundo e limpa o intervalo quando desmontado.
                        `,
                    },
                    {
                        primary: '2. Hooks de efeito para ciclo de vida em componentes funcionais',
                        secondary: 'Em componentes funcionais, o hook useEffect substitui os métodos de ciclo de vida para realizar efeitos colaterais, como chamadas a APIs ou timers.',
                        codeSnippet: `
        import React, { useState, useEffect } from 'react';
    
        function Relogio() {
            const [data, setData] = useState(new Date());
    
            useEffect(() => {
                const timerID = setInterval(() => setData(new Date()), 1000);
    
                return () => clearInterval(timerID);  // Limpa o timer quando o componente é desmontado
            }, []);
    
            return <h2>São {data.toLocaleTimeString()}.</h2>;
        }
    
        export default Relogio;
                        `,
                    },
                ],
            },
            {
                label: 'Componentes Reutilizáveis e Composição',
                description: 'A reutilização e composição de componentes é um dos principais benefícios do React, permitindo construir interfaces complexas a partir de componentes menores e mais simples.',
                subtopics: [
                    {
                        primary: '1. Composição de Componentes',
                        secondary: 'Componentes podem ser compostos de outros componentes, criando layouts e estruturas mais complexas de maneira simples e reutilizável.',
                        codeSnippet: `
        import React from 'react';
    
        function Botao(props) {
            return <button>{props.texto}</button>;
        }
    
        function CaixaDeBotao() {
            return (
                <div>
                    <Botao texto="Botão 1" />
                    <Botao texto="Botão 2" />
                    <Botao texto="Botão 3" />
                </div>
            );
        }
    
        export default CaixaDeBotao;
                        `,
                    },
                    {
                        primary: '2. Componentes de Ordem Superior (HOCs)',
                        secondary: 'HOCs são funções que recebem um componente como argumento e retornam um novo componente com comportamento ou dados adicionais.',
                        codeSnippet: `
        import React from 'react';
    
        function withDadosExtra(WrappedComponent) {
            return function ComponenteComDadosExtra(props) {
                const dadosExtra = { nome: 'Pedro', idade: 25 };
                return <WrappedComponent {...props} dadosExtra={dadosExtra} />;
            };
        }
    
        function ExibirDados(props) {
            return (
                <div>
                    <p>Nome: {props.dadosExtra.nome}</p>
                    <p>Idade: {props.dadosExtra.idade}</p>
                </div>
            );
        }
    
        export default withDadosExtra(ExibirDados);
    
        // O HOC withDadosExtra adiciona informações extras ao componente ExibirDados.
                        `,
                    },
                    {
                        primary: '3. Render Props',
                        secondary: 'Render props é uma técnica que permite que um componente reutilizável receba uma função que define o que deve ser renderizado.',
                        codeSnippet: `
        import React, { useState } from 'react';
    
        function Mouse({ render }) {
            const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    
            const handleMouseMove = (e) => {
                setMousePos({ x: e.clientX, y: e.clientY });
            };
    
            return <div onMouseMove={handleMouseMove}>{render(mousePos)}</div>;
        }
    
        function Exemplo() {
            return (
                <Mouse render={({ x, y }) => (
                    <p>Posição do mouse: {x}, {y}</p>
                )} />
            );
        }
    
        export default Exemplo;
    
        // O componente Mouse usa render props para permitir flexibilidade na renderização baseada na posição do mouse.
                        `,
                    },
                ],
            },
            {
                label: 'Boas Práticas em React',
                description: 'Dicas para escrever código React eficiente e fácil de manter.',
                subtopics: [
                    {
                        primary: '1. Utilize componentes funcionais e hooks',
                        secondary: 'Os componentes funcionais são mais leves e, com hooks como useState e useEffect, você pode gerenciar estado e efeitos colaterais de maneira simples.',
                        codeSnippet: `
        import React, { useState } from 'react';
    
        function Contador() {
            const [contador, setContador] = useState(0);
    
            return (
                <div>
                    <p>Você clicou {contador} vezes</p>
                    <button onClick={() => setContador(contador + 1)}>Clique aqui</button>
                </div>
            );
        }
    
        export default Contador;
                        `,
                    },
                    {
                        primary: '2. Evite re-renderizações desnecessárias com useMemo e useCallback',
                        secondary: 'Esses hooks ajudam a evitar re-renderizações que podem afetar o desempenho da aplicação.',
                        codeSnippet: `
        import React, { useMemo, useCallback } from 'react';
    
        function ListaDobro({ itens }) {
            const listaDobro = useMemo(() => itens.map(item => item * 2), [itens]);
    
            const handleClick = useCallback(() => {
                console.log('Botão clicado');
            }, []);
    
            return (
                <div>
                    <ul>
                        {listaDobro.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <button onClick={handleClick}>Clique</button>
                </div>
            );
        }
    
        export default ListaDobro;
                        `,
                    },
                    {
                        primary: '3. Use prop-types para validar props',
                        secondary: 'PropTypes ajudam a validar as props recebidas pelos componentes, garantindo que o tipo correto de dado esteja sendo passado.',
                        codeSnippet: `
        import React from 'react';
        import PropTypes from 'prop-types';
    
        function Saudacao({ nome }) {
            return <h1>Olá, {nome}!</h1>;
        }
    
        Saudacao.propTypes = {
            nome: PropTypes.string.isRequired,
        };
    
        export default Saudacao;
                        `,
                    },
                ],
            },
        ],
    },
    {
        label: 'React Native',
        title: 'Sobre React Native',
        path: '/sobre/react-native',
        icon: mdiReact,
        topics: [
            {
                label: 'O que é React Native?',
                description: 'React Native é um framework de código aberto desenvolvido pelo Facebook que permite criar aplicativos móveis para iOS e Android usando JavaScript e React. Ele possibilita o desenvolvimento de aplicações nativas, compartilhando a maior parte do código entre as plataformas.'
            },
            {
                label: 'Componentes Básicos',
                description: 'React Native oferece uma série de componentes básicos que são mapeados diretamente para elementos nativos do Android e iOS. Esses componentes permitem construir interfaces de usuário de maneira semelhante ao React, porém com foco em dispositivos móveis.',
                subtopics: [
                    {
                        primary: '1. View e Text',
                        secondary: '`<View>` é o contêiner básico para layouts em React Native, similar a uma `<div>` no HTML. `<Text>` é usado para exibir textos.',
                        codeSnippet: `
        import React from 'react';
        import { View, Text } from 'react-native';
    
        const App = () => {
            return (
                <View>
                    <Text>Olá, React Native!</Text>
                </View>
            );
        };
    
        export default App;
                        `,
                    },
                    {
                        primary: '2. Image',
                        secondary: 'O componente `<Image>` é utilizado para exibir imagens. Você pode carregar imagens locais ou de URLs remotas.',
                        codeSnippet: `
        import React from 'react';
        import { Image, View } from 'react-native';
    
        const App = () => {
            return (
                <View>
                    <Image
                        source={{ uri: 'https://reactnative.dev/img/tiny_logo.png' }}
                        style={{ width: 50, height: 50 }}
                    />
                </View>
            );
        };
    
        export default App;
                        `,
                    },
                    {
                        primary: '3. Button e TouchableOpacity',
                        secondary: '`<Button>` é o componente básico de botão, mas para personalizações mais avançadas, o `<TouchableOpacity>` é preferível.',
                        codeSnippet: `
        import React from 'react';
        import { Button, View, Alert, TouchableOpacity, Text } from 'react-native';
    
        const App = () => {
            return (
                <View>
                    <Button title="Pressione-me" onPress={() => Alert.alert('Botão Pressionado!')} />
    
                    <TouchableOpacity onPress={() => Alert.alert('TouchableOpacity Pressionado!')}>
                        <Text style={{ padding: 10, backgroundColor: 'blue', color: 'white' }}>Botão Personalizado</Text>
                    </TouchableOpacity>
                </View>
            );
        };
    
        export default App;
                        `,
                    },
                ],
            },
            {
                label: 'Estilos em React Native',
                description: 'Em React Native, a estilização é feita em JavaScript, diferente do CSS utilizado na web. A propriedade `style` é usada para definir estilos e você pode utilizar o objeto `StyleSheet` para centralizar as definições de estilo.',
                subtopics: [
                    {
                        primary: '1. StyleSheet e Propriedade Style',
                        secondary: 'O objeto `StyleSheet.create()` permite criar uma coleção de estilos reutilizáveis, que podem ser aplicados aos componentes via a propriedade `style`.',
                        codeSnippet: `
        import React from 'react';
        import { View, Text, StyleSheet } from 'react-native';
    
        const App = () => {
            return (
                <View style={styles.container}>
                    <Text style={styles.texto}>Olá, Mundo!</Text>
                </View>
            );
        };
    
        const styles = StyleSheet.create({
            container: {
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            },
            texto: {
                fontSize: 20,
                color: 'blue',
            },
        });
    
        export default App;
                        `,
                    },
                    {
                        primary: '2. Flexbox para Layouts',
                        secondary: 'React Native usa o modelo Flexbox para lidar com layouts. A propriedade `flex` e as regras de `justifyContent` e `alignItems` ajudam a organizar o layout de maneira responsiva.',
                        codeSnippet: `
        import React from 'react';
        import { View, Text, StyleSheet } from 'react-native';
    
        const App = () => {
            return (
                <View style={styles.container}>
                    <View style={styles.box}>
                        <Text>Caixa 1</Text>
                    </View>
                    <View style={styles.box}>
                        <Text>Caixa 2</Text>
                    </View>
                    <View style={styles.box}>
                        <Text>Caixa 3</Text>
                    </View>
                </View>
            );
        };
    
        const styles = StyleSheet.create({
            container: {
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
            },
            box: {
                width: 100,
                height: 100,
                backgroundColor: 'lightblue',
                justifyContent: 'center',
                alignItems: 'center',
            },
        });
    
        export default App;
                        `,
                    },
                    {
                        primary: '3. Unidades de Medida e Escalonamento',
                        secondary: 'React Native não usa unidades como `px`, `em` ou `%`. Em vez disso, ele usa uma unidade que se ajusta à densidade da tela.',
                        codeSnippet: `
        const styles = StyleSheet.create({
            box: {
                width: 100,  // Medida adaptativa à densidade da tela
                height: 100,
                margin: 10,
                padding: 20,
            },
        });
                        `,
                    },
                ],
            },
            {
                label: 'Navegação em React Native',
                description: 'React Native utiliza bibliotecas como `react-navigation` para gerenciar a navegação entre telas. Há diferentes tipos de navegação, como navegação em pilha (stack), abas (tabs), e drawer (menu lateral).',
                subtopics: [
                    {
                        primary: '1. Navegação em Pilha (Stack Navigator)',
                        secondary: 'A navegação em pilha permite empilhar telas e navegar entre elas. O histórico de navegação é mantido, permitindo voltar para telas anteriores.',
                        codeSnippet: `
        import React from 'react';
        import { Button, View, Text } from 'react-native';
        import { NavigationContainer } from '@react-navigation/native';
        import { createStackNavigator } from '@react-navigation/stack';
    
        const Stack = createStackNavigator();
    
        function HomeScreen({ navigation }) {
            return (
                <View>
                    <Text>Página Inicial</Text>
                    <Button
                        title="Ir para Detalhes"
                        onPress={() => navigation.navigate('Details')}
                    />
                </View>
            );
        }
    
        function DetailsScreen() {
            return (
                <View>
                    <Text>Detalhes</Text>
                </View>
            );
        }
    
        export default function App() {
            return (
                <NavigationContainer>
                    <Stack.Navigator>
                        <Stack.Screen name="Home" component={HomeScreen} />
                        <Stack.Screen name="Details" component={DetailsScreen} />
                    </Stack.Navigator>
                </NavigationContainer>
            );
        }
                        `,
                    },
                    {
                        primary: '2. Navegação em Abas (Bottom Tab Navigator)',
                        secondary: 'A navegação em abas exibe diferentes páginas na forma de abas na parte inferior da tela, permitindo fácil alternância entre seções do aplicativo.',
                        codeSnippet: `
        import React from 'react';
        import { Text, View } from 'react-native';
        import { NavigationContainer } from '@react-navigation/native';
        import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
    
        function HomeScreen() {
            return (
                <View>
                    <Text>Home</Text>
                </View>
            );
        }
    
        function SettingsScreen() {
            return (
                <View>
                    <Text>Configurações</Text>
                </View>
            );
        }
    
        const Tab = createBottomTabNavigator();
    
        export default function App() {
            return (
                <NavigationContainer>
                    <Tab.Navigator>
                        <Tab.Screen name="Home" component={HomeScreen} />
                        <Tab.Screen name="Settings" component={SettingsScreen} />
                    </Tab.Navigator>
                </NavigationContainer>
            );
        }
                        `,
                    },
                    {
                        primary: '3. Navegação com Menu Lateral (Drawer Navigator)',
                        secondary: 'O menu lateral permite a navegação entre páginas usando um drawer que desliza da lateral da tela, geralmente usado para navegação de aplicativos complexos.',
                        codeSnippet: `
        import React from 'react';
        import { Text, View } from 'react-native';
        import { NavigationContainer } from '@react-navigation/native';
        import { createDrawerNavigator } from '@react-navigation/drawer';
    
        function HomeScreen() {
            return (
                <View>
                    <Text>Home</Text>
                </View>
            );
        }
    
        function ProfileScreen() {
            return (
                <View>
                    <Text>Perfil</Text>
                </View>
            );
        }
    
        const Drawer = createDrawerNavigator();
    
        export default function App() {
            return (
                <NavigationContainer>
                    <Drawer.Navigator>
                        <Drawer.Screen name="Home" component={HomeScreen} />
                        <Drawer.Screen name="Profile" component={ProfileScreen} />
                    </Drawer.Navigator>
                </NavigationContainer>
            );
        }
                        `,
                    },
                ],
            },
            {
                label: 'Acesso a APIs Nativas',
                description: 'React Native oferece suporte para acessar APIs nativas do dispositivo, como câmera, localização, notificações e muito mais, por meio de bibliotecas de terceiros ou criando seus próprios módulos nativos.',
                subtopics: [
                    {
                        primary: '1. Câmera e Galeria',
                        secondary: 'O React Native permite capturar fotos da câmera ou acessar a galeria de fotos do dispositivo utilizando bibliotecas como `react-native-image-picker`.',
                        codeSnippet: `
        import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
    
        function openCamera() {
            launchCamera({ mediaType: 'photo' }, response => {
                console.log(response.uri);
            });
        }
    
        function openGallery() {
            launchImageLibrary({ mediaType: 'photo' }, response => {
                console.log(response.uri);
            });
        }
                        `,
                    },
                    {
                        primary: '2. Geolocalização',
                        secondary: 'Com a API de geolocalização, você pode obter a localização atual do usuário. A permissão de localização deve ser solicitada no dispositivo.',
                        codeSnippet: `
        import React, { useEffect, useState } from 'react';
        import { View, Text } from 'react-native';
        import Geolocation from '@react-native-community/geolocation';
    
        const App = () => {
            const [location, setLocation] = useState(null);
    
            useEffect(() => {
                Geolocation.getCurrentPosition(
                    position => {
                        setLocation(position.coords);
                    },
                    error => console.log(error),
                    { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
                );
            }, []);
    
            return (
                <View>
                    {location ? (
                        <Text>Latitude: {location.latitude}, Longitude: {location.longitude}</Text>
                    ) : (
                        <Text>Obtendo localização...</Text>
                    )}
                </View>
            );
        };
    
        export default App;
                        `,
                    },
                    {
                        primary: '3. Notificações Push',
                        secondary: 'O React Native suporta notificações push usando serviços como Firebase Cloud Messaging (FCM), permitindo enviar alertas para os usuários.',
                        codeSnippet: `
        import messaging from '@react-native-firebase/messaging';
    
        async function requestUserPermission() {
            const authStatus = await messaging().requestPermission();
            const enabled = authStatus === messaging.AuthorizationStatus.AUTHORIZED || 
                            authStatus === messaging.AuthorizationStatus.PROVISIONAL;
    
            if (enabled) {
                console.log('Permissão de notificação concedida:', authStatus);
            }
        }
    
        messaging().onMessage(async remoteMessage => {
            console.log('Mensagem recebida no app:', remoteMessage);
        });
                        `,
                    },
                ],
            },
            {
                label: 'Boas Práticas em React Native',
                description: 'Dicas para escrever código React Native eficiente, limpo e fácil de manter.',
                subtopics: [
                    {
                        primary: '1. Evite Renderizações Desnecessárias',
                        secondary: 'Use o hook `useMemo` ou componentes memoizados para evitar renderizações desnecessárias que possam impactar a performance da aplicação.',
                        codeSnippet: `
        import React, { useMemo } from 'react';
        import { Text, View } from 'react-native';
    
        const MeuComponente = ({ items }) => {
            const itemDobrado = useMemo(() => items.map(item => item * 2), [items]);
    
            return (
                <View>
                    {itemDobrado.map((item, index) => (
                        <Text key={index}>{item}</Text>
                    ))}
                </View>
            );
        };
                        `,
                    },
                    {
                        primary: '2. Use Componentes Nativos Sempre que Possível',
                        secondary: 'Sempre que possível, use componentes nativos ou bibliotecas que mapeiam diretamente para elementos nativos para maximizar a performance e a aparência nativa.',
                        codeSnippet: `
        import { Button } from 'react-native';  // Melhor do que construir um botão do zero com View/Text
                        `,
                    },
                    {
                        primary: '3. Gerencie Estados com Context ou Redux',
                        secondary: 'Use bibliotecas como Context API ou Redux para gerenciar estados globais e evitar passar props desnecessárias entre componentes.',
                        codeSnippet: `
        import React, { createContext, useContext, useState } from 'react';
    
        const TemaContext = createContext();
    
        const TemaProvider = ({ children }) => {
            const [tema, setTema] = useState('claro');
            const alternarTema = () => setTema(tema === 'claro' ? 'escuro' : 'claro');
    
            return (
                <TemaContext.Provider value={{ tema, alternarTema }}>
                    {children}
                </TemaContext.Provider>
            );
        };
    
        const useTema = () => useContext(TemaContext);
    
        const App = () => {
            const { tema, alternarTema } = useTema();
    
            return (
                <View>
                    <Button title="Alternar Tema" onPress={alternarTema} />
                    <Text>Tema Atual: {tema}</Text>
                </View>
            );
        };
    
        export default () => (
            <TemaProvider>
                <App />
            </TemaProvider>
        );
                        `,
                    },
                ],
            },
        ],
    },

    {
        label: 'Node.js',
        title: 'Sobre Node.js',
        path: '/sobre/nodejs',
        icon: mdiNodejs,
        topics: [
            {
                label: 'O que é Node.js?',
                description: 'Node.js é um ambiente de execução JavaScript no lado do servidor. Ele permite que você execute código JavaScript fora do navegador, usando o mecanismo V8 do Google Chrome. Node.js é amplamente utilizado para criar APIs, servidores web, aplicativos em tempo real e outras soluções de back-end.'
            },
            {
                label: 'Módulos Nativos no Node.js',
                description: 'Node.js vem com uma série de módulos nativos que permitem lidar com operações de E/S, manipulação de arquivos, redes e muito mais. Alguns dos módulos mais comuns são `fs`, `http`, `path`, e `os`.',
                subtopics: [
                    {
                        primary: '1. Manipulação de Arquivos com o Módulo fs',
                        secondary: 'O módulo `fs` (file system) permite a leitura e gravação de arquivos no sistema de arquivos. Ele oferece operações síncronas e assíncronas.',
                        codeSnippet: `
        const fs = require('fs');
    
        // Leitura de arquivo de forma assíncrona
        fs.readFile('exemplo.txt', 'utf8', (err, data) => {
            if (err) throw err;
            console.log(data);
        });
    
        // Escrita em arquivo de forma assíncrona
        fs.writeFile('saida.txt', 'Olá, Node.js!', (err) => {
            if (err) throw err;
            console.log('Arquivo salvo!');
        });
                        `,
                    },
                    {
                        primary: '2. Criação de Servidor HTTP com o Módulo http',
                        secondary: 'O módulo `http` permite a criação de servidores web simples. Ele lida com requisições HTTP e respostas.',
                        codeSnippet: `
        const http = require('http');
    
        const server = http.createServer((req, res) => {
            res.statusCode = 200;
            res.setHeader('Content-Type', 'text/plain');
            res.end('Olá, Mundo!\n');
        });
    
        server.listen(3000, () => {
            console.log('Servidor rodando em http://localhost:3000/');
        });
                        `,
                    },
                    {
                        primary: '3. Manipulação de Caminhos com o Módulo path',
                        secondary: 'O módulo `path` permite trabalhar com caminhos de diretórios e arquivos de forma cross-platform, garantindo que seu código funcione em diferentes sistemas operacionais.',
                        codeSnippet: `
        const path = require('path');
    
        const diretorio = path.join(__dirname, 'pasta', 'arquivo.txt');
        console.log(diretorio);  // Output: /caminho/para/pasta/arquivo.txt
                        `,
                    },
                ],
            },
            {
                label: 'Eventos e Streams',
                description: 'Node.js é baseado em uma arquitetura orientada a eventos, onde muitas de suas operações são realizadas de forma assíncrona através de eventos. Streams são uma forma eficiente de ler e escrever dados, especialmente em operações de I/O.',
                subtopics: [
                    {
                        primary: '1. EventEmitter para Gerenciamento de Eventos',
                        secondary: '`EventEmitter` é uma classe do Node.js usada para manipular eventos personalizados. Ele permite que objetos emitam eventos e que outros respondam a esses eventos.',
                        codeSnippet: `
        const EventEmitter = require('events');
        const emitter = new EventEmitter();
    
        // Ouvindo o evento
        emitter.on('saudacao', (nome) => {
            console.log(\`Olá, \${nome}!\`);
        });
    
        // Emitindo o evento
        emitter.emit('saudacao', 'Pedro');
                        `,
                    },
                    {
                        primary: '2. Leituras e Escritas de Streams',
                        secondary: 'Streams são usados para processar grandes quantidades de dados de forma eficiente, dividindo-os em partes menores (chunks). Existem quatro tipos principais de streams: readable, writable, duplex e transform.',
                        codeSnippet: `
        const fs = require('fs');
    
        // Stream de leitura
        const streamDeLeitura = fs.createReadStream('arquivo-grande.txt', 'utf8');
        streamDeLeitura.on('data', (chunk) => {
            console.log('Novo chunk recebido:', chunk);
        });
    
        // Stream de escrita
        const streamDeEscrita = fs.createWriteStream('saida.txt');
        streamDeEscrita.write('Gravando dados em chunks...');
                        `,
                    },
                ],
            },
            {
                label: 'Express.js - Framework Web para Node.js',
                description: 'Express.js é um framework web minimalista para Node.js. Ele facilita a criação de servidores web robustos e APIs REST, fornecendo uma série de funcionalidades que simplificam o desenvolvimento de back-end.',
                subtopics: [
                    {
                        primary: '1. Configuração Básica do Express.js',
                        secondary: 'Para começar com o Express.js, é necessário criar um servidor e definir rotas para manipular diferentes requisições HTTP.',
                        codeSnippet: `
        const express = require('express');
        const app = express();
    
        app.get('/', (req, res) => {
            res.send('Olá, Express.js!');
        });
    
        app.listen(3000, () => {
            console.log('Servidor rodando em http://localhost:3000');
        });
                        `,
                    },
                    {
                        primary: '2. Middleware no Express.js',
                        secondary: 'Middlewares são funções que podem modificar as requisições ou respostas em uma aplicação Express. Eles são essenciais para processar dados, autenticar usuários e tratar erros.',
                        codeSnippet: `
        const express = require('express');
        const app = express();
    
        // Middleware para log de requisições
        app.use((req, res, next) => {
            console.log(\`Método: \${req.method}, URL: \${req.url}\`);
            next();
        });
    
        app.get('/', (req, res) => {
            res.send('Página Inicial');
        });
    
        app.listen(3000, () => {
            console.log('Servidor rodando em http://localhost:3000');
        });
                        `,
                    },
                    {
                        primary: '3. APIs REST com Express.js',
                        secondary: 'O Express.js facilita a criação de APIs RESTful, fornecendo métodos para manipular requisições HTTP como `GET`, `POST`, `PUT`, e `DELETE`.',
                        codeSnippet: `
        const express = require('express');
        const app = express();
        app.use(express.json());  // Middleware para processar JSON
    
        // Rotas REST
        app.get('/usuarios', (req, res) => {
            res.send('Listando usuários');
        });
    
        app.post('/usuarios', (req, res) => {
            const novoUsuario = req.body;
            res.send(\`Usuário \${novoUsuario.nome} criado com sucesso!\`);
        });
    
        app.listen(3000, () => {
            console.log('API rodando em http://localhost:3000');
        });
                        `,
                    },
                ],
            },
            {
                label: 'Trabalhando com Banco de Dados no Node.js',
                description: 'Node.js pode se conectar a diferentes tipos de banco de dados, como bancos de dados relacionais (MySQL, PostgreSQL) e bancos de dados NoSQL (MongoDB). Existem diversas bibliotecas que facilitam a integração com esses bancos de dados.',
                subtopics: [
                    {
                        primary: '1. Conectando ao MongoDB com Mongoose',
                        secondary: 'O MongoDB é um banco de dados NoSQL muito popular e o Mongoose é uma biblioteca que facilita a interação com ele, fornecendo esquemas e validação de dados.',
                        codeSnippet: `
        const mongoose = require('mongoose');
    
        // Conectando ao MongoDB
        mongoose.connect('mongodb://localhost:27017/meuBanco', { useNewUrlParser: true, useUnifiedTopology: true })
            .then(() => console.log('Conectado ao MongoDB'))
            .catch(err => console.log('Erro ao conectar ao MongoDB', err));
    
        // Definindo um esquema
        const usuarioSchema = new mongoose.Schema({
            nome: String,
            idade: Number,
        });
    
        // Criando um modelo
        const Usuario = mongoose.model('Usuario', usuarioSchema);
    
        // Criando um novo documento
        const novoUsuario = new Usuario({ nome: 'João', idade: 30 });
        novoUsuario.save()
            .then(() => console.log('Usuário salvo!'))
            .catch(err => console.log('Erro ao salvar usuário', err));
                        `,
                    },
                    {
                        primary: '2. Consultas SQL com MySQL',
                        secondary: 'Para trabalhar com bancos de dados relacionais como MySQL, você pode usar a biblioteca `mysql2` para realizar consultas e interagir com o banco de dados.',
                        codeSnippet: `
        const mysql = require('mysql2');
    
        // Criando conexão com o MySQL
        const connection = mysql.createConnection({
            host: 'localhost',
            user: 'root',
            database: 'meuBanco'
        });
    
        // Consulta SQL
        connection.query('SELECT * FROM usuarios', (err, results) => {
            if (err) throw err;
            console.log(results);
        });
                        `,
                    },
                    {
                        primary: '3. Pool de Conexões com PostgreSQL',
                        secondary: 'Para garantir uma melhor performance e gerenciamento de recursos, o PostgreSQL suporta o uso de pools de conexão, permitindo múltiplas conexões simultâneas de forma eficiente.',
                        codeSnippet: `
        const { Pool } = require('pg');
    
        // Criando pool de conexões
        const pool = new Pool({
            user: 'postgres',
            host: 'localhost',
            database: 'meuBanco',
            password: 'senha',
            port: 5432,
        });
    
        // Executando consulta
        pool.query('SELECT * FROM usuarios', (err, res) => {
            if (err) throw err;
            console.log(res.rows);
        });
                        `,
                    },
                ],
            },
            {
                label: 'Gerenciamento de Pacotes com NPM',
                description: 'O NPM (Node Package Manager) é o gerenciador de pacotes padrão do Node.js. Ele permite instalar, compartilhar e gerenciar dependências de bibliotecas externas em seus projetos.',
                subtopics: [
                    {
                        primary: '1. Instalação de Pacotes',
                        secondary: 'O comando `npm install` é usado para instalar pacotes, tanto localmente no projeto quanto globalmente.',
                        codeSnippet: `
        // Instalação de pacotes localmente
        npm install express
    
        // Instalação de pacotes globalmente
        npm install -g nodemon
                        `,
                    },
                    {
                        primary: '2. Scripts no package.json',
                        secondary: 'O arquivo `package.json` gerencia as dependências e scripts do projeto. Você pode adicionar scripts personalizados para facilitar o desenvolvimento.',
                        codeSnippet: `
        // package.json
        {
          "name": "meu-projeto",
          "version": "1.0.0",
          "scripts": {
            "start": "node app.js",
            "dev": "nodemon app.js"
          },
          "dependencies": {
            "express": "^4.17.1"
          }
        }
    
        // Executando os scripts
        npm run start
        npm run dev
                        `,
                    },
                    {
                        primary: '3. Uso de Versionamento Semântico',
                        secondary: 'O NPM usa versionamento semântico (semver) para gerenciar as versões das dependências. Isso ajuda a garantir a compatibilidade entre pacotes.',
                        codeSnippet: `
        // Exemplo de versionamento semântico
        "express": "^4.17.1",  // O ^ indica compatibilidade com versões futuras até 4.x.x
    
        // Atualizando pacotes para a última versão compatível
        npm update
                        `,
                    },
                ],
            },
            {
                label: 'Boas Práticas em Node.js',
                description: 'Dicas para escrever código Node.js eficiente, seguro e fácil de manter.',
                subtopics: [
                    {
                        primary: '1. Evite Bloquear o Event Loop',
                        secondary: 'O Event Loop é a base do Node.js. Evite bloquear o Event Loop com operações pesadas ou síncronas, que podem prejudicar o desempenho de todo o aplicativo.',
                        codeSnippet: `
        // Operação síncrona bloqueando o Event Loop (não recomendado)
        const fs = require('fs');
        const dados = fs.readFileSync('grande-arquivo.txt');
    
        // Operação assíncrona liberando o Event Loop (recomendado)
        fs.readFile('grande-arquivo.txt', (err, dados) => {
            if (err) throw err;
            console.log(dados);
        });
                        `,
                    },
                    {
                        primary: '2. Use Promises e async/await',
                        secondary: 'Para lidar com operações assíncronas de maneira mais clara e eficiente, utilize Promises e async/await em vez de callbacks aninhados (callback hell).',
                        codeSnippet: `
        const fs = require('fs').promises;
    
        async function lerArquivo() {
            try {
                const dados = await fs.readFile('exemplo.txt', 'utf8');
                console.log(dados);
            } catch (err) {
                console.error('Erro ao ler arquivo:', err);
            }
        }
    
        lerArquivo();
                        `,
                    },
                    {
                        primary: '3. Trate Exceções com try/catch',
                        secondary: 'Sempre trate exceções em código assíncrono para evitar que erros quebrem o fluxo de execução e prejudiquem a estabilidade da aplicação.',
                        codeSnippet: `
        async function conectarBD() {
            try {
                await mongoose.connect('mongodb://localhost:27017/meuBanco');
                console.log('Conectado ao banco de dados!');
            } catch (err) {
                console.error('Erro ao conectar ao banco de dados:', err);
            }
        }
    
        conectarBD();
                        `,
                    },
                ],
            },
        ],
    },
    {
        label: 'Html',
        title: 'Sobre HTML',
        path: '/sobre/html',
        icon: mdiLanguageHtml5,
        topics: [
            {
                label: 'O que é HTML?',
                description: 'HTML (HyperText Markup Language) é a linguagem padrão para criar páginas web. Ele define a estrutura e o conteúdo de uma página usando uma série de elementos e tags. HTML é o núcleo da web, permitindo a criação de documentos estruturados que podem ser lidos e renderizados por navegadores.'
            },
            {
                label: 'Estrutura de uma Página HTML',
                description: 'Uma página HTML é composta por uma série de elementos que formam a estrutura básica do documento. Ela começa com uma declaração do tipo de documento e inclui as seções `<html>`, `<head>`, e `<body>`.',
                subtopics: [
                    {
                        primary: '1. Estrutura Básica de um Documento HTML',
                        secondary: 'Todo documento HTML começa com a declaração do tipo de documento (`<!DOCTYPE html>`) e contém elementos essenciais como `<html>`, `<head>`, e `<body>`.',
                        codeSnippet: `
        <!DOCTYPE html>
        <html lang="pt-BR">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Título da Página</title>
        </head>
        <body>
            <h1>Bem-vindo ao HTML</h1>
            <p>Esta é a estrutura básica de uma página HTML.</p>
        </body>
        </html>
                        `,
                    },
                    {
                        primary: '2. Metadados no Cabeçalho',
                        secondary: 'O elemento `<head>` contém metadados sobre o documento, como o conjunto de caracteres (`<meta charset>`), o título da página (`<title>`), e links para arquivos externos (como CSS e JS).',
                        codeSnippet: `
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta name="description" content="Página de exemplo de HTML">
            <link rel="stylesheet" href="styles.css">
            <title>Minha Página</title>
        </head>
                        `,
                    },
                    {
                        primary: '3. Estrutura do Corpo do Documento',
                        secondary: 'O conteúdo visível de uma página HTML é inserido dentro da tag `<body>`. Elementos como títulos, parágrafos, imagens e links fazem parte dessa seção.',
                        codeSnippet: `
        <body>
            <h1>Título Principal</h1>
            <p>Este é um parágrafo de texto em uma página HTML.</p>
            <img src="imagem.jpg" alt="Descrição da imagem">
            <a href="https://exemplo.com">Clique aqui para visitar</a>
        </body>
                        `,
                    },
                ],
            },
            {
                label: 'Elementos de Texto',
                description: 'HTML oferece uma série de elementos para organizar e estilizar o texto. Isso inclui títulos, parágrafos, listas, links e formatação de texto como negrito, itálico e sublinhado.',
                subtopics: [
                    {
                        primary: '1. Títulos e Parágrafos',
                        secondary: 'Títulos são definidos pelas tags `<h1>` a `<h6>`, onde `<h1>` é o maior e mais importante, e `<h6>` o menor. Parágrafos são definidos pela tag `<p>`.',
                        codeSnippet: `
        <h1>Título Principal</h1>
        <h2>Subtítulo</h2>
        <p>Este é um parágrafo de texto que contém informações.</p>
                        `,
                    },
                    {
                        primary: '2. Listas Ordenadas e Não Ordenadas',
                        secondary: 'Listas ordenadas usam números e são definidas pela tag `<ol>`, enquanto listas não ordenadas usam marcadores e são definidas pela tag `<ul>`. Os itens de lista são definidos com a tag `<li>`.',
                        codeSnippet: `
        <ul>
            <li>Item de lista não ordenada 1</li>
            <li>Item de lista não ordenada 2</li>
        </ul>
    
        <ol>
            <li>Item de lista ordenada 1</li>
            <li>Item de lista ordenada 2</li>
        </ol>
                        `,
                    },
                    {
                        primary: '3. Formatação de Texto',
                        secondary: 'HTML oferece tags para aplicar formatação ao texto, como `<strong>` para negrito, `<em>` para itálico, e `<u>` para sublinhado.',
                        codeSnippet: `
        <p>Texto <strong>negrito</strong>, texto <em>itálico</em>, e texto <u>sublinhado</u>.</p>
                        `,
                    },
                ],
            },
            {
                label: 'Imagens e Links',
                description: 'Imagens e links são elementos essenciais em HTML, permitindo a inserção de conteúdo visual e navegação entre páginas.',
                subtopics: [
                    {
                        primary: '1. Inserindo Imagens',
                        secondary: 'O elemento `<img>` é usado para inserir imagens. Ele exige o atributo `src` para definir o caminho da imagem e o atributo `alt` para fornecer uma descrição alternativa.',
                        codeSnippet: `
        <img src="imagem.jpg" alt="Descrição da imagem" width="300" height="200">
                        `,
                    },
                    {
                        primary: '2. Criando Links',
                        secondary: 'Links são criados com o elemento `<a>`, que usa o atributo `href` para definir o destino do link. Eles podem ser usados para navegação interna ou externa.',
                        codeSnippet: `
        <a href="https://exemplo.com" target="_blank">Visite nosso site</a>
        <a href="#seção">Ir para a seção específica na mesma página</a>
                        `,
                    },
                    {
                        primary: '3. Links de Download e Imagens Clicáveis',
                        secondary: 'Links podem ser usados para fazer o download de arquivos ou tornar uma imagem clicável.',
                        codeSnippet: `
        <!-- Link para download -->
        <a href="arquivo.pdf" download>Baixar PDF</a>
    
        <!-- Imagem como link -->
        <a href="https://exemplo.com">
            <img src="imagem.jpg" alt="Imagem Clicável">
        </a>
                        `,
                    },
                ],
            },
            {
                label: 'Formulários',
                description: 'Os formulários são uma parte fundamental da interação com os usuários, permitindo a coleta de dados. O HTML oferece uma série de elementos para criar formulários interativos.',
                subtopics: [
                    {
                        primary: '1. Estrutura de um Formulário',
                        secondary: 'Os formulários são definidos pela tag `<form>`. O atributo `action` define para onde os dados serão enviados, e o atributo `method` define como os dados serão enviados (`GET` ou `POST`).',
                        codeSnippet: `
        <form action="/enviar-dados" method="POST">
            <label for="nome">Nome:</label>
            <input type="text" id="nome" name="nome" required>
            <button type="submit">Enviar</button>
        </form>
                        `,
                    },
                    {
                        primary: '2. Tipos de Inputs',
                        secondary: 'O elemento `<input>` pode ser usado para uma variedade de campos de entrada, como texto, senhas, e-mails, datas, e muito mais.',
                        codeSnippet: `
        <input type="text" name="nome" placeholder="Digite seu nome">
        <input type="email" name="email" placeholder="Digite seu e-mail">
        <input type="password" name="senha" placeholder="Digite sua senha">
        <input type="date" name="data">
                        `,
                    },
                    {
                        primary: '3. Elementos de Formulário Adicionais',
                        secondary: 'Além de inputs, você pode usar outros elementos de formulário como `<textarea>` para texto de várias linhas, `<select>` para listas suspensas, e `<button>` para botões.',
                        codeSnippet: `
        <textarea name="mensagem" rows="4" cols="50">Escreva sua mensagem aqui</textarea>
    
        <select name="opção">
            <option value="opcao1">Opção 1</option>
            <option value="opcao2">Opção 2</option>
        </select>
    
        <button type="submit">Enviar</button>
                        `,
                    },
                ],
            },
            {
                label: 'Semântica HTML',
                description: 'A semântica em HTML refere-se ao uso de elementos que descrevem claramente seu significado para navegadores e desenvolvedores. Elementos semânticos são essenciais para acessibilidade e SEO.',
                subtopics: [
                    {
                        primary: '1. Elementos Semânticos Comuns',
                        secondary: 'Elementos como `<header>`, `<footer>`, `<article>`, `<section>`, e `<nav>` são usados para estruturar a página de forma semântica, melhorando a acessibilidade e SEO.',
                        codeSnippet: `
        <header>
            <h1>Bem-vindo ao Meu Site</h1>
        </header>
    
        <nav>
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#sobre">Sobre</a></li>
            </ul>
        </nav>
    
        <article>
            <h2>Título do Artigo</h2>
            <p>Conteúdo do artigo.</p>
        </article>
    
        <footer>
            <p>&copy; 2024 Meu Site</p>
        </footer>
                        `,
                    },
                    {
                        primary: '2. Benefícios da Semântica para SEO e Acessibilidade',
                        secondary: 'A utilização correta de elementos semânticos ajuda os motores de busca a indexar o conteúdo e melhora a acessibilidade para leitores de tela.',
                        codeSnippet: `
        <section aria-labelledby="titulo-secao">
            <h2 id="titulo-secao">Seção Importante</h2>
            <p>Conteúdo desta seção importante.</p>
        </section>
                        `,
                    },
                    {
                        primary: '3. Tabelas Semânticas',
                        secondary: 'Tabelas semânticas usam os elementos `<table>`, `<thead>`, `<tbody>`, `<tr>`, `<th>`, e `<td>` para organizar dados tabulares de forma clara e acessível.',
                        codeSnippet: `
        <table>
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Idade</th>
                    <th>Profissão</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>João</td>
                    <td>30</td>
                    <td>Desenvolvedor</td>
                </tr>
            </tbody>
        </table>
                        `,
                    },
                ],
            },
            {
                label: 'Multimídia e APIs de HTML5',
                description: 'O HTML5 introduziu novos elementos para trabalhar com multimídia, como áudio e vídeo, além de APIs poderosas como `Canvas`, `Geolocation`, e `Local Storage`.',
                subtopics: [
                    {
                        primary: '1. Áudio e Vídeo',
                        secondary: 'Com HTML5, você pode incorporar mídia de áudio e vídeo diretamente em páginas web sem a necessidade de plugins externos.',
                        codeSnippet: `
        <audio controls>
            <source src="audio.mp3" type="audio/mpeg">
            Seu navegador não suporta o elemento de áudio.
        </audio>
    
        <video width="320" height="240" controls>
            <source src="video.mp4" type="video/mp4">
            Seu navegador não suporta o elemento de vídeo.
        </video>
                        `,
                    },
                    {
                        primary: '2. Canvas para Desenhos Gráficos',
                        secondary: 'A API `Canvas` permite desenhar gráficos, animações e até jogos diretamente no navegador.',
                        codeSnippet: `
        <canvas id="meuCanvas" width="200" height="200"></canvas>
        <script>
            let canvas = document.getElementById('meuCanvas');
            let ctx = canvas.getContext('2d');
            ctx.fillStyle = 'blue';
            ctx.fillRect(10, 10, 150, 100);
        </script>
                        `,
                    },
                    {
                        primary: '3. Local Storage e Geolocation',
                        secondary: 'O HTML5 introduziu o `Local Storage` para armazenar dados no navegador do usuário e a API `Geolocation` para obter a localização do usuário.',
                        codeSnippet: `
        <!-- Usando Local Storage -->
        <script>
            localStorage.setItem('nome', 'Pedro');
            let nome = localStorage.getItem('nome');
            console.log(nome);  // Output: Pedro
        </script>
    
        <!-- Usando Geolocation -->
        <script>
            navigator.geolocation.getCurrentPosition(function(pos) {
                console.log('Latitude: ' + pos.coords.latitude);
                console.log('Longitude: ' + pos.coords.longitude);
            });
        </script>
                        `,
                    },
                ],
            },
            {
                label: 'Boas Práticas em HTML',
                description: 'Dicas para escrever HTML organizado, acessível e otimizado para SEO.',
                subtopics: [
                    {
                        primary: '1. Use Tags Semânticas',
                        secondary: 'Usar elementos semânticos, como `<header>`, `<footer>`, e `<nav>`, torna o documento mais acessível e melhora o SEO.',
                        codeSnippet: `
        <header>
            <h1>Título da Página</h1>
        </header>
    
        <nav>
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#contato">Contato</a></li>
            </ul>
        </nav>
                        `,
                    },
                    {
                        primary: '2. Sempre Defina o Atributo Alt em Imagens',
                        secondary: 'Imagens devem sempre ter o atributo `alt` definido para melhorar a acessibilidade e fornecer descrições para motores de busca.',
                        codeSnippet: `
        <img src="imagem.jpg" alt="Descrição detalhada da imagem">
                        `,
                    },
                    {
                        primary: '3. Valide Seu HTML',
                        secondary: 'Use validadores de HTML para garantir que o código esteja bem formatado e conforme os padrões da web, garantindo compatibilidade entre navegadores.',
                        codeSnippet: `
        <!-- Validação de HTML -->
        https://validator.w3.org/
                        `,
                    },
                ],
            },
        ],
    },

    {
        label: 'Css',
        title: 'Sobre CSS',
        path: '/sobre/css',
        icon: mdiLanguageCss3,
        topics: [
            {
                label: 'O que é CSS?',
                description: 'CSS (Cascading Style Sheets) é uma linguagem de estilo usada para descrever a apresentação de um documento escrito em HTML ou XML. Ele permite controlar o layout, cores, fontes, espaçamentos e muito mais em uma página web.'
            },
            {
                label: 'Seletores',
                description: 'Seletores são padrões usados para selecionar os elementos que você deseja estilizar. Existem vários tipos de seletores em CSS, que vão desde seletores simples até combinadores complexos.',
                subtopics: [
                    {
                        primary: '1. Seletores Simples',
                        secondary: 'Seletores simples incluem seletores de tipo, de classe e de ID. Eles permitem estilizar elementos específicos de uma página.',
                        codeSnippet: `
        /* Seletor de tipo */
        h1 {
            color: blue;
        }
    
        /* Seletor de classe */
        .titulo {
            font-size: 24px;
        }
    
        /* Seletor de ID */
        #principal {
            margin: 20px;
        }
                        `,
                    },
                    {
                        primary: '2. Combinadores',
                        secondary: 'Combinadores permitem selecionar elementos com base em suas relações com outros elementos, como descendentes ou elementos adjacentes.',
                        codeSnippet: `
        /* Combinador de descendente */
        div p {
            color: green;
        }
    
        /* Combinador de adjacência */
        h1 + p {
            font-size: 18px;
        }
                        `,
                    },
                    {
                        primary: '3. Pseudo-classes e Pseudo-elementos',
                        secondary: 'Pseudo-classes e pseudo-elementos permitem selecionar elementos com base em seu estado ou adicionar estilos a partes específicas de um elemento.',
                        codeSnippet: `
        /* Pseudo-classe */
        a:hover {
            color: red;
        }
    
        /* Pseudo-elemento */
        p::first-letter {
            font-size: 200%;
            color: purple;
        }
                        `,
                    },
                ],
            },
            {
                label: 'Box Model',
                description: 'O modelo de caixa (box model) é um conceito fundamental no CSS que define como os elementos são representados como caixas retangulares. Ele é composto por conteúdo, padding, bordas e margens.',
                subtopics: [
                    {
                        primary: '1. Propriedades de Largura e Altura',
                        secondary: 'As propriedades `width` e `height` controlam as dimensões do conteúdo da caixa, enquanto o `padding`, `border` e `margin` influenciam o espaço ao redor do conteúdo.',
                        codeSnippet: `
        .caixa {
            width: 200px;
            height: 150px;
            padding: 10px;
            border: 2px solid black;
            margin: 20px;
        }
                        `,
                    },
                    {
                        primary: '2. Box Sizing',
                        secondary: 'A propriedade `box-sizing` controla como a largura e altura de um elemento são calculadas, incluindo ou excluindo o padding e as bordas.',
                        codeSnippet: `
        /* Inclui o padding e a borda no cálculo de largura/altura */
        .caixa {
            box-sizing: border-box;
            width: 200px;
            height: 150px;
            padding: 10px;
            border: 2px solid black;
        }
                        `,
                    },
                    {
                        primary: '3. Overflow',
                        secondary: 'A propriedade `overflow` controla o que acontece quando o conteúdo de uma caixa ultrapassa seu tamanho definido. Ela pode ser configurada para mostrar, ocultar ou adicionar rolagem.',
                        codeSnippet: `
        .caixa {
            width: 150px;
            height: 100px;
            overflow: auto;  /* Adiciona barra de rolagem se necessário */
        }
                        `,
                    },
                ],
            },
            {
                label: 'Posicionamento',
                description: 'O CSS oferece várias maneiras de posicionar elementos em uma página. As principais técnicas incluem posicionamento estático, relativo, absoluto e fixo.',
                subtopics: [
                    {
                        primary: '1. Posição Estática e Relativa',
                        secondary: 'O posicionamento estático é o padrão em CSS. O posicionamento relativo permite mover o elemento a partir de sua posição original.',
                        codeSnippet: `
        .elemento {
            position: relative;
            top: 20px;  /* Move o elemento 20px para baixo */
            left: 10px;  /* Move o elemento 10px para a direita */
        }
                        `,
                    },
                    {
                        primary: '2. Posição Absoluta',
                        secondary: 'Elementos com posicionamento absoluto são removidos do fluxo normal do documento e posicionados em relação ao seu elemento ancestral mais próximo com `position` diferente de `static`.',
                        codeSnippet: `
        .container {
            position: relative;
        }
    
        .elemento-absoluto {
            position: absolute;
            top: 0;
            left: 0;
        }
                        `,
                    },
                    {
                        primary: '3. Posição Fixa e Sticky',
                        secondary: 'O posicionamento fixo posiciona um elemento em relação à janela de visualização, enquanto o `position: sticky` permite que o elemento "grude" à posição à medida que a página é rolada.',
                        codeSnippet: `
        .elemento-fixo {
            position: fixed;
            bottom: 10px;
            right: 10px;
        }
    
        .menu-sticky {
            position: sticky;
            top: 0;
        }
                        `,
                    },
                ],
            },
            {
                label: 'Flexbox e Grid',
                description: 'Flexbox e Grid são dois dos principais modelos de layout em CSS, permitindo a criação de layouts complexos e responsivos de maneira eficiente.',
                subtopics: [
                    {
                        primary: '1. Flexbox',
                        secondary: 'O Flexbox é um modelo de layout unidimensional que permite distribuir o espaço entre os itens de um container e alinhar os itens de forma previsível.',
                        codeSnippet: `
        .container {
            display: flex;
            justify-content: space-between;  /* Distribui os itens uniformemente */
            align-items: center;  /* Alinha os itens verticalmente ao centro */
        }
    
        .item {
            flex: 1;  /* Cada item ocupa o mesmo espaço */
        }
                        `,
                    },
                    {
                        primary: '2. Grid',
                        secondary: 'O CSS Grid é um sistema bidimensional que permite a criação de layouts de grade, onde os elementos podem ser posicionados com base em linhas e colunas.',
                        codeSnippet: `
        .container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;  /* Define três colunas com largura igual */
            grid-template-rows: auto;
        }
    
        .item {
            grid-column: span 2;  /* Este item ocupa duas colunas */
        }
                        `,
                    },
                    {
                        primary: '3. Comparação entre Flexbox e Grid',
                        secondary: 'Embora ambos sejam modelos poderosos, Flexbox é mais adequado para layouts unidimensionais (horizontal ou vertical), enquanto Grid é ideal para layouts bidimensionais (linhas e colunas).',
                        codeSnippet: `
        /* Flexbox: Unidimensional */
        .flex-container {
            display: flex;
        }
    
        /* Grid: Bidimensional */
        .grid-container {
            display: grid;
        }
                        `,
                    },
                ],
            },
            {
                label: 'Responsividade e Media Queries',
                description: 'CSS oferece suporte a layouts responsivos, que se adaptam a diferentes tamanhos de tela e dispositivos. O uso de media queries é essencial para alcançar essa flexibilidade.',
                subtopics: [
                    {
                        primary: '1. Introdução a Media Queries',
                        secondary: 'Media queries permitem aplicar estilos diferentes com base nas características do dispositivo, como largura de tela, altura ou orientação.',
                        codeSnippet: `
        /* Estilo para dispositivos com largura de tela menor que 600px */
        @media (max-width: 600px) {
            .container {
                flex-direction: column;
            }
        }
                        `,
                    },
                    {
                        primary: '2. Layouts Responsivos com Flexbox e Grid',
                        secondary: 'Flexbox e Grid facilitam a criação de layouts que se adaptam automaticamente a diferentes larguras de tela, eliminando a necessidade de media queries complexas.',
                        codeSnippet: `
        .container {
            display: flex;
            flex-wrap: wrap;  /* Itens irão quebrar em múltiplas linhas, se necessário */
        }
    
        /* Usando Grid para criar layouts que se adaptam ao tamanho da tela */
        .grid-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }
                        `,
                    },
                    {
                        primary: '3. Viewport Units e Layouts Responsivos',
                        secondary: 'Unidades de medida como `vw` (viewport width) e `vh` (viewport height) são úteis para criar layouts fluidos que se ajustam ao tamanho da janela do navegador.',
                        codeSnippet: `
        .header {
            height: 50vh;  /* A altura do cabeçalho será 50% da altura da tela */
        }
    
        .section {
            width: 80vw;  /* A largura da seção será 80% da largura da tela */
        }
                        `,
                    },
                ],
            },
            {
                label: 'Animações e Transições',
                description: 'CSS permite criar animações e transições suaves, que melhoram a experiência do usuário ao interagir com a interface.',
                subtopics: [
                    {
                        primary: '1. Transições',
                        secondary: 'A propriedade `transition` permite adicionar um efeito de suavização entre dois estados de um elemento quando ele muda de estilo.',
                        codeSnippet: `
        .botao {
            background-color: blue;
            transition: background-color 0.3s ease-in-out;
        }
    
        .botao:hover {
            background-color: green;
        }
                        `,
                    },
                    {
                        primary: '2. Animações com @keyframes',
                        secondary: 'As animações permitem mudanças complexas no estilo de um elemento ao longo do tempo. A regra `@keyframes` define os pontos intermediários de uma animação.',
                        codeSnippet: `
        @keyframes deslizar {
            from {
                transform: translateX(0);
            }
            to {
                transform: translateX(100px);
            }
        }
    
        .caixa {
            animation: deslizar 2s ease-in-out;
        }
                        `,
                    },
                    {
                        primary: '3. Transformações 2D e 3D',
                        secondary: 'Transformações permitem alterar a forma de um elemento no espaço bidimensional ou tridimensional, usando `transform` para rotacionar, escalar, ou transladar elementos.',
                        codeSnippet: `
        .caixa {
            transform: rotate(45deg) scale(1.2);
            transition: transform 0.3s;
        }
    
        .caixa:hover {
            transform: rotate(0deg) scale(1);
        }
                        `,
                    },
                ],
            },
            {
                label: 'Boas Práticas em CSS',
                description: 'Dicas para escrever CSS eficiente, organizado e fácil de manter em projetos de qualquer tamanho.',
                subtopics: [
                    {
                        primary: '1. Estrutura Modular com BEM',
                        secondary: 'O BEM (Block, Element, Modifier) é uma convenção de nomenclatura que ajuda a estruturar e organizar CSS, tornando o código mais legível e fácil de manter.',
                        codeSnippet: `
        /* Exemplo de nomenclatura BEM */
        .menu__item {  /* Elemento item dentro do bloco menu */
            color: white;
        }
    
        .menu__item--ativo {  /* Modificador ativo do item */
            font-weight: bold;
        }
                        `,
                    },
                    {
                        primary: '2. Reduza o Uso de Seletores Aninhados',
                        secondary: 'Embora o aninhamento de seletores seja permitido, exagerar no aninhamento pode resultar em CSS difícil de depurar e manter.',
                        codeSnippet: `
        /* Exemplo de aninhamento simples e legível */
        .menu {
            background-color: #333;
        }
    
        .menu .menu__item {
            padding: 10px;
        }
                        `,
                    },
                    {
                        primary: '3. Evite Estilos Inline',
                        secondary: 'Estilos inline dificultam a manutenção e reutilização do CSS. Prefira sempre definir estilos em arquivos CSS externos.',
                        codeSnippet: `
        <!-- Estilo inline (não recomendado) -->
        <div style="color: red; font-size: 20px;">Texto</div>
    
        <!-- Estilo externo (recomendado) -->
        <div class="texto-vermelho">Texto</div>
    
        /* CSS */
        .texto-vermelho {
            color: red;
            font-size: 20px;
        }
                        `,
                    },
                ],
            },
        ],
    },

    {
        label: 'JavaScript',
        title: 'Sobre JavaScript',
        path: '/sobre/javascript',
        icon: mdiLanguageJavascript,
        topics: [
            {
                label: 'O que é JavaScript?',
                description: 'JavaScript é uma linguagem de programação leve e dinâmica, amplamente utilizada para desenvolvimento web. Ela permite a criação de páginas interativas e dinâmicas, além de ser utilizada em servidores com Node.js.'
            },
            {
                label: 'Tipos de Dados',
                description: 'JavaScript possui vários tipos de dados primitivos, como números, strings e booleanos, além de tipos mais complexos como objetos e arrays.',
                subtopics: [
                    {
                        primary: '1. Tipos Primitivos',
                        secondary: 'Os tipos primitivos em JavaScript incluem `number`, `string`, `boolean`, `undefined`, `null`, `symbol`, e `bigint`.',
                        codeSnippet: `
        let nome = 'João';  // string
        let idade = 30;  // number
        let ativo = true;  // boolean
        let indefinido;  // undefined
        let nulo = null;  // null
    
        console.log(typeof nome);  // Output: string
                        `,
                    },
                    {
                        primary: '2. Objetos',
                        secondary: 'Objetos em JavaScript são coleções de propriedades e valores. Eles podem conter funções (métodos) e dados.',
                        codeSnippet: `
        let pessoa = {
            nome: 'Maria',
            idade: 25,
            saudacao: function() {
                return 'Olá, ' + this.nome;
            }
        };
    
        console.log(pessoa.saudacao());  // Output: Olá, Maria
                        `,
                    },
                    {
                        primary: '3. Arrays',
                        secondary: 'Arrays são listas ordenadas de valores. Cada valor pode ser acessado por seu índice.',
                        codeSnippet: `
        let numeros = [10, 20, 30, 40];
        
        console.log(numeros[0]);  // Output: 10
        console.log(numeros.length);  // Output: 4
                        `,
                    },
                ],
            },
            {
                label: 'Funções',
                description: 'Funções são blocos de código reutilizáveis que executam uma tarefa ou calculam um valor. Em JavaScript, funções podem ser declaradas de várias formas.',
                subtopics: [
                    {
                        primary: '1. Funções Declarativas',
                        secondary: 'Uma função declarativa é declarada usando a palavra-chave `function`. Ela pode ser chamada antes de ser definida, devido ao hoisting.',
                        codeSnippet: `
        function somar(a, b) {
            return a + b;
        }
    
        console.log(somar(5, 3));  // Output: 8
                        `,
                    },
                    {
                        primary: '2. Funções de Expressão',
                        secondary: 'Uma função de expressão é atribuída a uma variável. Diferente da função declarativa, ela só pode ser chamada depois de ser definida.',
                        codeSnippet: `
        const subtrair = function(a, b) {
            return a - b;
        };
    
        console.log(subtrair(10, 3));  // Output: 7
                        `,
                    },
                    {
                        primary: '3. Arrow Functions',
                        secondary: 'Arrow functions são uma sintaxe mais concisa para definir funções. Elas não possuem seu próprio `this`, o que as torna úteis em certos contextos.',
                        codeSnippet: `
        const multiplicar = (a, b) => a * b;
    
        console.log(multiplicar(5, 4));  // Output: 20
                        `,
                    },
                ],
            },
            {
                label: 'Controle de Fluxo',
                description: 'O JavaScript possui várias estruturas de controle de fluxo, como condicionais (`if` e `else`), loops (`for`, `while`, `do...while`), e instruções de controle (`break`, `continue`).',
                subtopics: [
                    {
                        primary: '1. Condicionais',
                        secondary: 'As instruções condicionais permitem executar diferentes blocos de código com base em uma condição booleana.',
                        codeSnippet: `
        let idade = 20;
    
        if (idade >= 18) {
            console.log('Você é maior de idade.');
        } else {
            console.log('Você é menor de idade.');
        }
                        `,
                    },
                    {
                        primary: '2. Loops',
                        secondary: 'Loops permitem executar um bloco de código repetidamente enquanto uma condição for verdadeira. O `for` e `while` são os mais comuns.',
                        codeSnippet: `
        for (let i = 0; i < 5; i++) {
            console.log(i);
        }
    
        let j = 0;
        while (j < 5) {
            console.log(j);
            j++;
        }
                        `,
                    },
                    {
                        primary: '3. Break e Continue',
                        secondary: '`break` interrompe um loop completamente, enquanto `continue` pula para a próxima iteração do loop.',
                        codeSnippet: `
        for (let i = 0; i < 10; i++) {
            if (i === 5) {
                break;  // Interrompe o loop quando i for 5
            }
            console.log(i);
        }
    
        for (let i = 0; i < 10; i++) {
            if (i === 5) {
                continue;  // Pula para a próxima iteração quando i for 5
            }
            console.log(i);
        }
                        `,
                    },
                ],
            },
            {
                label: 'Manipulação de Objetos',
                description: 'Objetos são estruturas essenciais no JavaScript e há várias maneiras de manipulá-los. Métodos como `Object.keys`, `Object.values`, e `Object.entries` ajudam a percorrer e manipular os dados de um objeto.',
                subtopics: [
                    {
                        primary: '1. Acessando Propriedades',
                        secondary: 'Você pode acessar as propriedades de um objeto usando a notação de ponto ou a notação de colchetes.',
                        codeSnippet: `
        let carro = {
            marca: 'Toyota',
            modelo: 'Corolla',
            ano: 2020
        };
    
        console.log(carro.marca);  // Output: Toyota
        console.log(carro['modelo']);  // Output: Corolla
                        `,
                    },
                    {
                        primary: '2. Iterando sobre Objetos',
                        secondary: 'Você pode percorrer as propriedades de um objeto usando `for...in` ou usando métodos como `Object.keys`, `Object.values`, e `Object.entries`.',
                        codeSnippet: `
        let pessoa = {
            nome: 'Carlos',
            idade: 40,
            profissao: 'Engenheiro'
        };
    
        for (let chave in pessoa) {
            console.log(chave + ': ' + pessoa[chave]);
        }
    
        // Output:
        // nome: Carlos
        // idade: 40
        // profissao: Engenheiro
                        `,
                    },
                    {
                        primary: '3. Desestruturação de Objetos',
                        secondary: 'A desestruturação permite extrair propriedades de um objeto e armazená-las em variáveis de maneira mais prática.',
                        codeSnippet: `
        let usuario = {
            nome: 'Ana',
            idade: 28,
            cidade: 'São Paulo'
        };
    
        let { nome, cidade } = usuario;
        console.log(nome);  // Output: Ana
        console.log(cidade);  // Output: São Paulo
                        `,
                    },
                ],
            },
            {
                label: 'Assincronismo e Promises',
                description: 'JavaScript é uma linguagem assíncrona, o que significa que pode executar código sem esperar que outras tarefas sejam concluídas. Promises são uma maneira de lidar com operações assíncronas.',
                subtopics: [
                    {
                        primary: '1. Callbacks',
                        secondary: 'Um callback é uma função passada como argumento para outra função, sendo executada após a conclusão de uma operação assíncrona.',
                        codeSnippet: `
        function buscarDados(callback) {
            setTimeout(() => {
                callback('Dados obtidos com sucesso!');
            }, 2000);
        }
    
        buscarDados(function(resultado) {
            console.log(resultado);  // Output: Dados obtidos com sucesso!
        });
                        `,
                    },
                    {
                        primary: '2. Promises',
                        secondary: 'Promises representam o valor eventual de uma operação assíncrona. Elas podem estar em três estados: pendente, resolvida, ou rejeitada.',
                        codeSnippet: `
        let promessa = new Promise(function(resolve, reject) {
            let sucesso = true;
    
            if (sucesso) {
                resolve('Operação bem-sucedida!');
            } else {
                reject('Erro na operação.');
            }
        });
    
        promessa
            .then(result => console.log(result))  // Output: Operação bem-sucedida!
            .catch(error => console.error(error));
                        `,
                    },
                    {
                        primary: '3. Async/Await',
                        secondary: '`async` e `await` são maneiras mais modernas e concisas de lidar com operações assíncronas no JavaScript.',
                        codeSnippet: `
        async function buscarDados() {
            let resposta = await fetch('https://api.example.com/dados');
            let dados = await resposta.json();
            return dados;
        }
    
        buscarDados()
            .then(dados => console.log(dados))
            .catch(erro => console.error(erro));
                        `,
                    },
                ],
            },
            {
                label: 'Classes e Herança',
                description: 'A partir do ECMAScript 2015 (ES6), JavaScript introduziu classes como uma maneira de definir objetos e herança de forma mais parecida com outras linguagens de programação.',
                subtopics: [
                    {
                        primary: '1. Definindo Classes',
                        secondary: 'Classes são uma forma de definir modelos de objetos. Elas encapsulam dados (propriedades) e comportamentos (métodos).',
                        codeSnippet: `
        class Animal {
            constructor(nome) {
                this.nome = nome;
            }
    
            falar() {
                console.log(this.nome + ' faz um som.');
            }
        }
    
        let animal = new Animal('Cachorro');
        animal.falar();  // Output: Cachorro faz um som.
                        `,
                    },
                    {
                        primary: '2. Herança',
                        secondary: 'Herança permite criar novas classes baseadas em outras classes. A palavra-chave `extends` é usada para herdar de uma classe existente.',
                        codeSnippet: `
        class Animal {
            constructor(nome) {
                this.nome = nome;
            }
    
            falar() {
                console.log(this.nome + ' faz um som.');
            }
        }
    
        class Cachorro extends Animal {
            falar() {
                console.log(this.nome + ' late.');
            }
        }
    
        let cachorro = new Cachorro('Rex');
        cachorro.falar();  // Output: Rex late.
                        `,
                    },
                ],
            },
            {
                label: 'Boas Práticas em JavaScript',
                description: 'Dicas para escrever código JavaScript eficiente, legível e fácil de manter.',
                subtopics: [
                    {
                        primary: '1. Use const e let em vez de var',
                        secondary: 'Sempre que possível, utilize `const` para valores constantes e `let` para variáveis que podem mudar. Evite o uso de `var` para evitar problemas de escopo.',
                        codeSnippet: `
        const PI = 3.14;
        let idade = 25;
    
        idade = 26;  // Pode ser atualizado
        // PI = 3.1415;  // Erro: Não pode reatribuir uma constante
                        `,
                    },
                    {
                        primary: '2. Evite funções aninhadas demais',
                        secondary: 'Funções aninhadas tornam o código mais difícil de ler e manter. Sempre que possível, modularize o código e evite a complexidade desnecessária.',
                        codeSnippet: `
        function processarDados(dados) {
            let resultado = [];
    
            dados.forEach(function(item) {
                resultado.push(item * 2);
            });
    
            return resultado;
        }
    
        console.log(processarDados([1, 2, 3]));  // Output: [2, 4, 6]
                        `,
                    },
                    {
                        primary: '3. Utilize template literals para strings',
                        secondary: 'Template literals facilitam a criação de strings complexas ou interpoladas em JavaScript.',
                        codeSnippet: `
        let nome = 'Carlos';
        let idade = 30;
    
        let mensagem = \`Meu nome é \${nome} e eu tenho \${idade} anos.\`;
    
        console.log(mensagem);  // Output: Meu nome é Carlos e eu tenho 30 anos.
                        `,
                    },
                ],
            },
        ],
    },
    {
        label: 'TypeScript',
        title: 'Sobre TypeScript',
        path: '/sobre/typescript',
        icon: mdiLanguageTypescript,
        topics: [
            {
                label: 'O que é TypeScript?',
                description: 'TypeScript é uma linguagem de programação que é um superconjunto de JavaScript, adicionando tipos estáticos opcionais e outros recursos. Ele ajuda a evitar erros comuns e torna o código mais previsível e fácil de manter.'
            },
            {
                label: 'Tipos Estáticos',
                description: 'Uma das maiores vantagens do TypeScript é a adição de tipos estáticos, permitindo que os desenvolvedores definam os tipos de variáveis, parâmetros e retornos de funções.',
                subtopics: [
                    {
                        primary: '1. Tipagem Básica',
                        secondary: 'Você pode definir o tipo de uma variável no momento da sua declaração, garantindo que ela só possa conter valores do tipo definido.',
                        codeSnippet: `
        let nome: string = 'Pedro';
        let idade: number = 25;
        let isAtivo: boolean = true;
    
        function saudacao(nome: string): string {
            return 'Olá, ' + nome;
        }
    
        console.log(saudacao(nome));  // Output: Olá, Pedro
                        `,
                    },
                    {
                        primary: '2. Tipos de Arrays e Tuplas',
                        secondary: 'Arrays e tuplas podem ter seus tipos definidos no TypeScript, garantindo que cada elemento do array ou tupla siga o tipo esperado.',
                        codeSnippet: `
        let numeros: number[] = [1, 2, 3, 4, 5];  // Array de números
    
        let pessoa: [string, number] = ['Pedro', 25];  // Tupla onde o primeiro valor é string e o segundo é number
    
        console.log(pessoa[0]);  // Output: Pedro
                        `,
                    },
                    {
                        primary: '3. Tipos de Objetos',
                        secondary: 'Os tipos de objetos podem ser definidos explicitamente, garantindo que as propriedades de um objeto tenham o tipo correto.',
                        codeSnippet: `
        let usuario: { nome: string; idade: number; isAtivo: boolean } = {
            nome: 'Maria',
            idade: 30,
            isAtivo: true
        };
    
        console.log(usuario.nome);  // Output: Maria
                        `,
                    },
                ],
            },
            {
                label: 'Interfaces e Tipos',
                description: 'Interfaces e tipos são estruturas no TypeScript que permitem a definição de contratos para objetos, funções, e outras estruturas, garantindo que elas sigam uma forma predefinida.',
                subtopics: [
                    {
                        primary: '1. Definindo Interfaces',
                        secondary: 'Uma interface define a estrutura de um objeto ou função. Ela descreve quais propriedades o objeto deve ter e quais tipos essas propriedades devem possuir.',
                        codeSnippet: `
        interface Pessoa {
            nome: string;
            idade: number;
            saudar(): string;
        }
    
        let pessoa: Pessoa = {
            nome: 'João',
            idade: 35,
            saudar() {
                return 'Olá, ' + this.nome;
            }
        };
    
        console.log(pessoa.saudar());  // Output: Olá, João
                        `,
                    },
                    {
                        primary: '2. Tipos Personalizados (Type Aliases)',
                        secondary: 'Os tipos personalizados (type aliases) permitem criar tipos complexos usando tipos já existentes. Eles são usados para definir combinações de tipos de dados ou simplificar declarações.',
                        codeSnippet: `
        type Idade = number;
        type Nome = string;
    
        type Pessoa = {
            nome: Nome;
            idade: Idade;
        };
    
        let pessoa: Pessoa = {
            nome: 'Ana',
            idade: 28
        };
    
        console.log(pessoa.nome);  // Output: Ana
                        `,
                    },
                    {
                        primary: '3. Diferença entre Interfaces e Types',
                        secondary: 'A principal diferença entre interfaces e types no TypeScript é que interfaces podem ser estendidas e mescladas, enquanto types são mais rígidos, sendo usados para tipos mais simples ou combinações de tipos.',
                        codeSnippet: `
        interface A {
            propA: string;
        }
    
        interface B extends A {
            propB: number;
        }
    
        let obj: B = {
            propA: 'Teste',
            propB: 123
        };
    
        type C = { propC: boolean };
        type D = C & { propD: string };  // Combinação de tipos
    
        let obj2: D = {
            propC: true,
            propD: 'Outro Teste'
        };
                        `,
                    },
                ],
            },
            {
                label: 'Funções e Tipos',
                description: 'TypeScript permite definir tipos de parâmetros e retornos de funções, garantindo maior segurança e previsibilidade ao escrever funções.',
                subtopics: [
                    {
                        primary: '1. Tipos de Parâmetros',
                        secondary: 'Os parâmetros de uma função podem ter seus tipos definidos, garantindo que a função só será chamada com argumentos válidos.',
                        codeSnippet: `
        function somar(a: number, b: number): number {
            return a + b;
        }
    
        console.log(somar(5, 3));  // Output: 8
                        `,
                    },
                    {
                        primary: '2. Funções com Parâmetros Opcionais',
                        secondary: 'Você pode definir parâmetros opcionais em uma função, permitindo que ela seja chamada sem todos os argumentos.',
                        codeSnippet: `
        function apresentar(nome: string, idade?: number): string {
            if (idade) {
                return \`Olá, meu nome é \${nome} e tenho \${idade} anos.\`;
            } else {
                return \`Olá, meu nome é \${nome}.\`;
            }
        }
    
        console.log(apresentar('Carlos'));  // Output: Olá, meu nome é Carlos.
        console.log(apresentar('Carlos', 30));  // Output: Olá, meu nome é Carlos e tenho 30 anos.
                        `,
                    },
                    {
                        primary: '3. Funções Assíncronas e Promises',
                        secondary: 'TypeScript suporta a definição de tipos para funções assíncronas e seus retornos, como Promises, garantindo que os valores retornados sejam do tipo correto.',
                        codeSnippet: `
        async function buscarDados(url: string): Promise<string> {
            const resposta = await fetch(url);
            const dados = await resposta.json();
            return dados;
        }
    
        buscarDados('https://api.example.com')
            .then(dados => console.log(dados))
            .catch(erro => console.error(erro));
                        `,
                    },
                ],
            },
            {
                label: 'Enums e Generics',
                description: 'Enums e Generics são recursos avançados do TypeScript que ajudam a lidar com cenários mais complexos, permitindo a criação de tipos flexíveis e reutilizáveis.',
                subtopics: [
                    {
                        primary: '1. Enums',
                        secondary: 'Enums são uma maneira de definir um conjunto de valores nomeados que representam estados ou categorias dentro do código.',
                        codeSnippet: `
        enum Direcao {
            Norte,
            Sul,
            Leste,
            Oeste
        }
    
        let direcaoAtual: Direcao = Direcao.Norte;
    
        console.log(direcaoAtual);  // Output: 0 (representação do valor Norte)
                        `,
                    },
                    {
                        primary: '2. Generics',
                        secondary: 'Generics permitem criar funções e classes que podem trabalhar com diferentes tipos de dados sem perder a tipagem.',
                        codeSnippet: `
        function retornarElemento<T>(elemento: T): T {
            return elemento;
        }
    
        console.log(retornarElemento(10));  // Output: 10 (number)
        console.log(retornarElemento('Texto'));  // Output: Texto (string)
                        `,
                    },
                    {
                        primary: '3. Classes Genéricas',
                        secondary: 'Classes também podem ser genéricas, permitindo trabalhar com diferentes tipos mantendo a consistência e reutilização.',
                        codeSnippet: `
        class Caixa<T> {
            private conteudo: T;
    
            constructor(conteudo: T) {
                this.conteudo = conteudo;
            }
    
            getConteudo(): T {
                return this.conteudo;
            }
        }
    
        let caixaString = new Caixa<string>('Um texto');
        console.log(caixaString.getConteudo());  // Output: Um texto
    
        let caixaNumero = new Caixa<number>(123);
        console.log(caixaNumero.getConteudo());  // Output: 123
                        `,
                    },
                ],
            },
            {
                label: 'Integração com JavaScript',
                description: 'TypeScript é totalmente compatível com JavaScript. Isso significa que você pode usar bibliotecas JavaScript existentes e gradualmente migrar seu código para TypeScript.',
                subtopics: [
                    {
                        primary: '1. Integração com Bibliotecas JavaScript',
                        secondary: 'Você pode usar qualquer biblioteca JavaScript em projetos TypeScript, e se necessário, adicionar tipos a elas através de arquivos de declaração de tipos (.d.ts).',
                        codeSnippet: `
        // Usando uma biblioteca JavaScript comum (exemplo com Lodash)
        import _ from 'lodash';
    
        let numeros = [10, 20, 30];
        let max = _.max(numeros);
    
        console.log(max);  // Output: 30
                        `,
                    },
                    {
                        primary: '2. Migrando JavaScript para TypeScript',
                        secondary: 'Você pode migrar código JavaScript para TypeScript aos poucos, adicionando tipos gradualmente. O TypeScript permite uma adoção progressiva, o que facilita a transição.',
                        codeSnippet: `
        // Código JavaScript original
        function somar(a, b) {
            return a + b;
        }
    
        // Código migrado para TypeScript
        function somarTS(a: number, b: number): number {
            return a + b;
        }
    
        console.log(somarTS(5, 3));  // Output: 8
                        `,
                    },
                ],
            },
        ],
    },

]
